import { createSelector } from 'reselect';

const getConfigInfo = (state) => state && state.config;
const getAuthInfo = (state) => state && state.auth;
const getClientInfo = (state) => state && state.commonClients;


export const getLogOutUrl = createSelector([getConfigInfo], (configInfo) =>
  configInfo && configInfo.client && configInfo.client.uiBaseUrl
    ? `${configInfo.client.uiBaseUrl}logout`
    : '',
);

export const getUserReAuthUrl = createSelector([getConfigInfo], (configInfo) =>
  configInfo && configInfo.client && configInfo.client.coreUrl
    ? `user/auth`
    : 'user/auth',
);

export const updateUserLocaleUrl = createSelector(
  [getConfigInfo],
  (configInfo) =>
    configInfo && configInfo.client && configInfo.client.coreUrl
      ? `${configInfo.client.coreUrl}user/locale`
      : '',
);

export const getPermissionsList = createSelector(
  [getAuthInfo],
  (authInfo) => authInfo.permissions,
);

export const getClient = createSelector(
  [getClientInfo],
  (clientInfo) => clientInfo.clientInfo,
);

