/* eslint-disable */
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  DialogTitle,
  DialogActions,
  DialogContent,
} from 'components/DialogBox';
import { useForm } from 'react-hook-form';
import IntlMessages from 'util/IntlMessages';
import {
  closeAddNewOptionsDialog,
  selectAddNewOptions,
  disableAddNewOptions,
  addNewOptions,
} from '../ducks/actions';
import validationMessages from './validationMessages';
import {
  EMAIL_VALIDATION_REGEX,
  TEXT_FIELD_VALIDATION_REGEX,
} from 'modules/cbre-flow-Common/constants/Regex';
import TextBox from 'components/TextBox';
import { Button, Dialog } from '@mui/material';
import '../styles/addNewOptions.scss';
import ControlledTextBox from 'components/ControlledTextBox';
import RenderInput from 'components/renderInput';

const validations = {
  required: "textbox.required",
  shouldBeLessThan: "textbox.dateDiffLesser",
  shouldBeGreaterThan: "textbox.dateDiffGreater"
}


const AddNewOptionsDialog = (props) => {
 
  
  const methods = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      firstName: '',
      lastName: '',
      email: '',
    },
  });

  const {
    register,
    handleSubmit,
    reset,
    control,
    formState: { errors, isValid },
    getValues,
    setValue,
    trigger,
  } = methods;

  const userSaveApi = props.userSaveApi;
  const dispatch = useDispatch();
  const { open, error, messageCode, body, mode, cb } = useSelector((state) => state.addOptions);
  const textBoxRegex = TEXT_FIELD_VALIDATION_REGEX;
  const emailRegex = EMAIL_VALIDATION_REGEX;
  const handleClose = () => {
    if (error) {
      const payload = {
        error: false,
        messageCode: '',
      };
      dispatch(closeAddNewOptionsDialog(payload));
    } else {
      dispatch(closeAddNewOptionsDialog());
    }
  };

  const handleBlur = async (e) => {
    e.target.value = e.target.value.trim();
    if (e.target.value === '') {
      errors[`${e.target.name}`] = {
        type: 'pattern',
      };
      await trigger(`${e.target.name}`);
    }
  };
  const handleOptionsInfoSubmit = () => {
    const values = getValues();

    if(mode === "lookups" && typeof cb === "function"){
      handleClose();
      return cb(values)
    }
    
    values.displayName = `${values.firstName} ${values.lastName}`;
    const payload = {
      saveApiUrl: userSaveApi,
      obj: values,
    };
    if (error) {
      payload.errorDetails = {
        error: false,
        messageCode: '',
      };
    }
    dispatch(addNewOptions(payload));
  };

  return (
    <>
      <Dialog
        onClose={handleClose}
        aria-labelledby="new-project-add-option-confirm-dialog-title"
        open={open}
        {...props}
        fullWidth={true}
        maxWidth="md"
        className="new-project-add-option-confirm-dialog"
        disableBackdropClick={true}
      >
        <DialogTitle className="np-add-option-title" onClose={handleClose}>
          <IntlMessages id="cbre-flow-add-new-options.title.addNew" />
        </DialogTitle>
        <DialogContent className="np-add-option-content">
          <div className="row">
            <div className="col-12" style={{ height: '19px' }}>
              {error ? (
                messageCode === 'USER_EXISTS_ERROR' ||
                messageCode === 'CONTACT_EXISTS_ERROR' ? (
                  <p className="add-option-err-msg">
                    {
                      <IntlMessages id="cbre-flow-add-new-options.form.userduplicateerror" />
                    }
                  </p>
                ) : (
                  <p className="add-option-err-msg">
                    {
                      <IntlMessages id="cbre-flow-add-new-options.form.genericerror" />
                    }
                  </p>
                )
              ) : null}
            </div>
            {mode === "contacts" && <><div className="col-custom col-md-6 col-sm-6 col-xs-12">
              <TextBox
                variant="outlined"
                label={
                  <IntlMessages id="cbre-flow-add-new-options.textbox.firstnamelabel" />
                }
                name="firstName"
                inputProps={{ maxLength: 200 }}
                onBlur={handleBlur}
                inputRef={register('firstName', {
                  required: true,
                  pattern: textBoxRegex,
                })}
                errors={errors?.firstName ? errors.firstName.type : ''}
                errorMessages={validationMessages.firstName}
                module="cbre-flow-add-new-options."
              />
            </div>
            <div className="col-custom col-md-6 col-sm-6 col-xs-12">
              <TextBox
                variant="outlined"
                label={
                  <IntlMessages id="cbre-flow-add-new-options.textbox.lastnamelabel" />
                }
                name="lastName"
                inputProps={{ maxLength: 200 }}
                onBlur={handleBlur}
                inputRef={register('lastName', {
                  required: true,
                  pattern: {
                    value: textBoxRegex,
                  },
                })}
                errors={errors.lastName ? errors.lastName.type : ''}
                errorMessages={validationMessages.lastName}
                module="cbre-flow-add-new-options."
              />
            </div>
            <div className="col-custom col-md-12 col-sm-12 col-xs-12">
              <TextBox
                variant="outlined"
                label={
                  <IntlMessages id="cbre-flow-add-new-options.textbox.emaillabel" />
                }
                name="email"
                inputProps={{ maxLength: 200 }}
                onBlur={handleBlur}
                inputRef={register('email', {
                  required: true,
                  pattern: {
                    value: emailRegex,
                  },
                })}
                errors={errors.email ? errors.email.type : ''}
                errorMessages={validationMessages.email}
                module="cbre-flow-add-new-options."
              />
            </div>
            </>}
            {mode === "lookups" && <><div className="col-custom col-md-12 col-sm-12 col-xs-12">
             
              {RenderInput({
                type: "text",
                methods,
                errorMessages: validations,
                intlValues: { name: <IntlMessages id="cbre-flow-add-new-options.textbox.name" values={{star:""}}/> },
                label: <IntlMessages id="cbre-flow-add-new-options.textbox.name" values={{star: "*"}} />,
                id: "lookupName",
                name: "lookupName",
                defaultValue: undefined,
                module:"",
                rules: { required: true },
                className: "",
                // focusField
              })}
            </div></>}
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            color="primary"
            onClick={handleClose}
            className="pjm-btn pjm-btn-sm "
            data-testid="addClientCancelBtn"
          >
            <span>
              <IntlMessages id="cbre-flow-add-new-options.button.cancel" />
            </span>
          </Button>
          <Button
            variant="contained"
            color="primary"
            className="pjm-btn pjm-btn-sm "
            data-testid="addClientSubmitBtn"
            disabled={!isValid}
            type="submit"
            onClick={handleOptionsInfoSubmit}
          >
            <span>
              <IntlMessages id="cbre-flow-add-new-options.button.submit" />
            </span>
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default AddNewOptionsDialog;
