import * as React from "react";
import CloseIcon from '@mui/icons-material/Close';
import { ButtonBase } from "@mui/material";
import { getSite } from "../ducks/selectors";
import { useSelector } from "react-redux";


type Contact = {
  siteContactName: string;
  siteContactEmail: string;
  description: string;
};

type ContactSectionProps = {
  contacts: Contact[];
};

const ContactSection: React.FC<ContactSectionProps> = ({ contacts }) => (
  <section className="contact-section">
    <div className="contact-header">
      <h2 style={{ flex: 2 }}>{"Contact"}</h2>
      <h2 style={{ flex: 1 }}>{"How I can help"}</h2>
    </div>
    {contacts.map((contact, index) => (
      <article key={index} className="contact-card">
        <h3>{contact.siteContactName}</h3>
        <a href={`mailto:${contact.siteContactEmail}`} className="contact-email">{contact.siteContactEmail}</a>
        <p>{contact.description}</p>
      </article>
    ))}
  </section>
);

const contactsData = [
  {
    title: "Contact",
    howIcanHelp: "How I can help",
    contacts: [
      {
        name: "CBRE Site Team",
        email: "eLIS.Springhouse@cbre.com",
        description: "General inquiries about the ELIS and CBRE services"
      },
      {
        name: "Agilent Site Team",
        email: "jnj-lms.shm@agilent.com",
        description: "General inquiries about your Agilent Service"
      },
      {
        name: "Jan Suessenguth - Instrument Lifecycle Coordinator",
        email: "jan.suessenguth@cbre.com",
        description: "Onboarding your new instruments into the lab and decommissioning your old instrumentation"
      },
      {
        name: "Uilton Dossantos - Site Lead",
        email: "uilton.dossantos@cbre.com",
        description: "I ensure ELIS provides the best services for your lab"
      }
    ]
  }
];

const HelpInfoPopup: React.FC<{ onClose: () => void }> = ({ onClose }) => {
  const { sitecontacts: siteContacts = [] } = useSelector(getSite)
  return (
    <>
      <section className="help-section">
        <div className="help-header">
          <h1>Need help?</h1>
          <ButtonBase onClick={onClose} focusRipple>
            <CloseIcon />
          </ButtonBase>

        </div>
        {
          siteContacts.length === 0 ? (
            <div className="help-content">
              <h3 className="text-center"> No help available at this time.</h3></div>)
            : <ContactSection contacts={siteContacts} />

        }

      </section>
    </>
  )
};

export default HelpInfoPopup;