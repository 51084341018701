
/* eslint-disable */
import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import Common from './Common';
import Auth from './Auth';
import Settings from './Settings';
import pendingApproval from 'modules/cbre-flow-lab-pending-approval/ducks/reducers';
import SiteLocation from 'modules/cbre-flow-site-location/ducks/reducers';
import UserInfo from 'modules/cbre-flow-api-ducks/reducers';
import AddInstrument from 'modules/cbre-flow-lab-add-instrument/ducks/reducers';
import WorkRequest from 'modules/cbre-flow-lab-new-work-request/ducks/reducers';
import AddOptions from 'modules/cbre-flow-add-new-options/ducks/reducers';
import FilterForm from 'modules/cbre-flow-lab-instrument-filter/ducks/reducers';
import RequestFilterForm from 'modules/cbre-flow-request-filter/ducks/reducers';
import UpcomingMaintenance from 'modules/cbre-flow-lab-upcoming-maintenance/ducks/reducers';
import UserManagement from 'modules/Admin/cbre-flow-user-management/ducks/reducers';
import SearchAddUser from 'modules/Admin/cbre-flow-user-management-search-add-user/ducks/reducers';
import AddClient from 'modules/Admin/cbre-flow-newclient/ducks/reducers';
import AppsList from 'modules/cbre-flow-api-ducks/reducers/apps';


const rootReducer = (history:any) => ({
  router: connectRouter(history),
  settings: Settings,
  auth: Auth,
  common: Common,
  client: Common,
  addOptions: AddOptions,
  pendingApproval,
  "cbre-flow-site-location": SiteLocation,
  "cbre-flow-api-ducks": combineReducers({UserInfo, AppsList}),
  "cbre-flow-lab-add-instrument": AddInstrument,
  "cbre-flow-lab-new-work-request": WorkRequest,
  "cbre-flow-lab-instrument-filter": FilterForm,
  "cbre-flow-request-filter": RequestFilterForm,
  "cbre-flow-lab-upcoming-maintenance": UpcomingMaintenance,
});

export default rootReducer;

export type RootState = ReturnType<typeof rootReducer>;