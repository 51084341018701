import { clearCommonAlerts } from 'modules/cbre-flow-Common/actions';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Alerts from './Alerts/Index';


const CommonAlert: React.FC = () => {
    const dispatch = useDispatch();
    const { alerts } = useSelector((state:any) => state.common)

    const handleRequestClose = () => {
        dispatch(clearCommonAlerts())
    }

    return (
        <Alerts alertState={alerts?.type} handleRequestClose={handleRequestClose} message={alerts?.message}/>
    );
};

export default CommonAlert;