
/* eslint-disable */
import {
  GET_ALL_LOOKUPS_SUCCESS,
} from './constants';
import {CBRE_FLOW_LAB_INSTRUMENT_FILTER_GET_ALL_LOOKUPS_SUCCESS} from "../ducks/constants";

const INIT_STATE = {
  lookupData:{},
  contactList:[],
  contactApproversList:{},
  defaultManufacturerList: []
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case CBRE_FLOW_LAB_INSTRUMENT_FILTER_GET_ALL_LOOKUPS_SUCCESS:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};
