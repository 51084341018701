/* eslint-disable */
/* eslint-disable no-extra-parens,global-require */
import React, { useState, useEffect, Fragment, useRef } from 'react';
import { Link, useLocation, withRouter } from 'react-router-dom';
import { connect, useDispatch, useSelector } from 'react-redux';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import NotificationsIcon from '@mui/icons-material/Notifications';
import { Dropdown, DropdownMenu, DropdownToggle } from 'reactstrap';
import {
  COLLAPSED_DRAWER,
  FIXED_DRAWER,
  MINI_DRAWER,
} from 'modules/cbre-flow-Common/constants/ActionTypes';
import {
  switchLanguage,
  toggleCollapsedNav,
  updateLocaleSelection,
} from 'modules/cbre-flow-Common/actions/Setting';
import IntlMessages from 'util/IntlMessages';
import Avatar from '@mui/material/Avatar';
import UserInfoPopup from 'components/UserInfo/UserInfoPopup';
import Drawer from '@mui/material/Drawer';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import { WHITE } from 'modules/cbre-flow-Common/constants/ThemeColors';
import Badge from '@mui/material/Badge';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import {
  getLogOutUrl,
  updateUserLocaleUrl,
} from 'modules/cbre-flow-Common/selectors';
import SideBarContent from '../../../SideBar/SideBarContent';
import {
  defaultLang,
  languageData as language,
} from 'components/LanguageSwitcher/data';
import { Track } from 'containers/Services/AnalyticsProvider';
import { Button, ButtonBase, Popover } from '@mui/material';
import HelpIcon from '@mui/icons-material/Help';
import AppsIcon from '@mui/icons-material/Apps';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { ExternalReports, HelpInfoPopup, LocationSelection } from 'modules/cbre-flow-site-location';

// eslint-disable-next-line complexity
const Index = (props) => {
  const { location, hideMenu, logOutUrl, isFusion } = props;
  const dispatch = useDispatch();
  const [languageData, setLanguageData] = useState(language);
  const [prevUrl, setPrevUrl] = useState(null);
  const { navCollapsed } = useSelector(({ common }) => common);
  const { userDisplayName, userEmailId, authUser } = useSelector(
    ({ auth }) => auth,
  );
  const userLocale = localStorage.getItem('locale');
  const localeUrl = useSelector(updateUserLocaleUrl);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [appsAnchorEl, setAppsAnchorEl] = React.useState(null);
  const [locationAnchorEl, setLocationAnchorEl] = React.useState(null);
  const showLanguageSelection = true; // location.pathname.indexOf('cpp') === 1;
  const { drawerType, locale, themeColor } = useSelector(
    ({ settings }) => settings,
  );
  const { siteInfo } = useSelector((state)=> state["cbre-flow-site-location"]) || {};
  const { siteName, description } = siteInfo || {};
  const [userInfo, setUserInfo] = useState(false);
  const [helpInfo, setHelpInfo] = useState(false);
  const [reportsInfo, setReportsInfo] = useState(false);
  const [locationInfo, setLocationInfo] = useState(false);

  const isDashboard = location.pathname === '/dashboard';

  useEffect(() => {
    const currentPath = location.pathname.toLowerCase();
    if (currentPath.includes('programmanagement')) {
      const languageData = [];
      languageData.push(language[0]);
      setLanguageData(languageData);
    }
  }, [location.pathname]);

  const changeLanguage = (selectedLang) => {
    dispatch(switchLanguage(selectedLang));
    document.dispatchEvent(
      new CustomEvent('languageChange', { detail: selectedLang }),
    );
  };

  useEffect(() => {
    const fusionProjectsListObj = {
      pageIndex: 0,
      isSearching: true,
    };

    const paths = ['pda', 'cpp', '/capitalplan', '/capitalPlan', '/myprojects'];
    if (paths.some((path) => location.pathname.includes(path))) {
    }

    const [englishUS, chinese, englishUK, french] = language;
    const onlyUSChineseSupport = [
      'dashboard',
      'pda',
      'capitalplan',
      'estimator',
      'projecttracker',
      'fusion',
      'accessmanagement',
      'myprojects',
    ];
    const allLangSupport = ['cpp'];
    const onlyUSEnglishSupport = ['programmanagement'];

    const updateDropdown = (path, lang) => {
      if (location.pathname.startsWith(prevUrl)) return false;
      const isPathAvailable = path.some((url) => {
        if (location.pathname.toLowerCase().startsWith(`/${url}`)) {
          setPrevUrl(`/${url}`);
          return true;
        }
      });
      if (isPathAvailable) {
        setLanguageData([...lang]);
        const isLocaleAvailable = lang.find((d) => d.locale === userLocale);
        if (!isLocaleAvailable) {
          localStorage.setItem(
            'beforeProgrammaticallyUpdatedLocale',
            userLocale,
          );
          changeLanguage(defaultLang);
        } else {
          const userSelectedLocale = localStorage.getItem(
            'beforeProgrammaticallyUpdatedLocale',
          );
          if (userSelectedLocale && userSelectedLocale !== userLocale) {
            const getUserSelectedLanguage = lang.find(
              (d) => d.locale === userSelectedLocale,
            );
            if (getUserSelectedLanguage)
              changeLanguage(getUserSelectedLanguage);
          }
        }
      }
    };
    updateDropdown(allLangSupport, language);
    updateDropdown(onlyUSChineseSupport, [englishUS, chinese]);
    updateDropdown(onlyUSEnglishSupport, [englishUS]);

    // after switching to fusion and locale is not set to en, will set initially to en
    //   if (location.pathname.indexOf('fusion') === 1 && locale.locale !== 'en_US') {
    //     dispatch(switchLanguage(languageData[0]));
    //   }

    //   if(!showLanguageSelection && location.pathname.indexOf('fusion') === -1){
    //     dispatch(switchLanguage(languageData[0]));
    //   }
    //   else if (showLanguageSelection &&  location.pathname.indexOf('fusion') === -1){
    //     const selectedLocale = languageData.filter((lngItem) => lngItem.locale === userLocale);
    //     dispatch(switchLanguage(selectedLocale ? selectedLocale[0] : defaultLang));
    //   }
  }, [location.pathname]);

  useEffect(() => {
    if (userLocale && showLanguageSelection) {
      const selectedLocale = languageData.find(
        (lngItem) => lngItem.locale === userLocale,
      );
      if (selectedLocale && userLocale !== selectedLocale.locale) {
        changeLanguage(selectedLocale);
      }
    }
  }, [userLocale]);

  const onUserInfoSelect = () => {
    setUserInfo(!userInfo);
  };
  const onHelpInfoSelect = () => {
    setHelpInfo(!helpInfo);
  };
  const onToggleCollapsedNav = () => {
    const val = !navCollapsed;
    dispatch(toggleCollapsedNav(val));
  };

  const onSwitchLanguage = (event) => {
    const langVal = languageData.find(
      (lngItem) => lngItem.languageId === event.target.value,
    );
    Track('LANGUAGE_SELECTION', { Language: langVal });
    if (langVal) {
      changeLanguage(langVal);
      if (location.pathname.indexOf('fusion') === -1) {
        dispatch(
          updateLocaleSelection({
            url: localeUrl,
            payload: { locale: langVal.locale },
          }),
        );
      }
      localStorage.setItem('beforeProgrammaticallyUpdatedLocale', '');
    }
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleAppsClick = (event) => {
    setAppsAnchorEl(event.currentTarget);
  };

  const handleAppsClose = () => {
    setAppsAnchorEl(null);
  };

  const handleLocationClick = (event) => {
    setLocationAnchorEl(event.currentTarget);
  };

  const handleLocationClose = () => {
    setLocationAnchorEl(null);
  };

  const sideDrawerStyle = drawerType.includes(FIXED_DRAWER)
    ? 'd-xl-flex'
    : drawerType.includes(COLLAPSED_DRAWER)
      ? ''
      : 'd-flex';
  const logoImage = themeColor.includes(WHITE)
    ? require('assets/images/logo/logo-inverse.svg').default
    : themeColor === 'green' && location.pathname === '/dashboard'
      ? require('assets/images/logo/logo-inverse.svg').default
      : require('assets/images/logo/logo-inverse-white.svg').default;

  let type = 'permanent';
  if (
    drawerType.includes(COLLAPSED_DRAWER) ||
    (drawerType.includes(FIXED_DRAWER) && window.innerWidth < 1200)
  ) {
    type = 'temporary';
  }
  const drawerStyle = drawerType.includes(FIXED_DRAWER)
    ? 'd-block d-xl-none'
    : drawerType.includes(COLLAPSED_DRAWER)
      ? 'd-block'
      : 'd-none';
  return (
    <AppBar className="app-main-header" elevation={0}>
      {!hideMenu &&
      !(
        drawerType.includes(MINI_DRAWER) ||
        (drawerType.includes(FIXED_DRAWER) && window.innerWidth >= 1200)
      ) ? (
        <div className={`app-sidebar d-none ${sideDrawerStyle}`}>
          <Drawer
            className="app-sidebar-content"
            variant={type}
            open={type.includes('temporary') ? navCollapsed : true}
            onClose={onToggleCollapsedNav}
            classes={{
              paper: 'side-nav top-49',
            }}
          >
            {/* <UserInfo/> */}
            <SideBarContent />
          </Drawer>
        </div>
      ) : (
        ''
      )}
      <Toolbar
        disableGutters={false}
        className={`app-toolbar p-3 ${
          location.pathname === '/dashboard'
            ? 'app-toolbar-dashboard'
            : 'app-toolbar-other'
        }`}
      >
        {!hideMenu && (
          <Fragment>
            <IconButton
              className={`pjm-menu-icon mr-2 ${drawerStyle}`}
              aria-label="Menu"
              onClick={onToggleCollapsedNav}
            >
              <span>
                <i className="zmdi zmdi-menu hamburger-menu-icon"></i>
              </span>
            </IconButton>
          </Fragment>
        )}
        {!hideMenu && (
          <Link
            className={` app-logo  mr-2 d-none d-sm-block ${!props.hideMenu ? '' : 'hide-menu'}`}
            to="/dashboard"
          >            
            <IntlMessages id="header.pjmTechnologyPortal" />
            <span className="left-spaced">
              <IntlMessages id="header.fromCbre" />
            </span>
          </Link>
        )}
        {hideMenu && (
          <span
            className={` app-logo  mr-2 d-none d-sm-block ${!props.hideMenu ? '' : 'hide-menu'}`}
            to="/"
          >
            <IntlMessages id="header.pjmTechnologyPortal" />
            <span className="left-spaced">
              <IntlMessages id="header.fromCbre" />
            </span>
          </span>
        )}
        {/* <SearchBox styleName="d-none d-lg-block" placeholder=""
                     onChange={updateSearchText}
                     value={searchText}/> */}
        <ul className="header-notifications list-inline ml-auto">
          {/*          {!hideMenu && !(location.pathname.includes('/dashboard')) && (
            <li className="list-inline-item mr-2">
              <Link className={`action-button mr-2 d-block`} to="/pda/myactions">
                <div className={`d-none d-sm-block`}><IntlMessages id="header.actions" /></div>
                <div className={`d-block d-sm-none`}>
                  <NotificationsIcon fontSize="small" />
                </div>

                {totalRecords > 0 &&
                  <span>{totalRecords}</span>}
              </Link>
            </li>
          )}*/}
          {/* {(showLanguageSelection || props.isFusion) && (
            <li className="list-inline-item mr-2">
              <FormControl variant="standard" className="w-200 header-language-select">
              <div className='header-language-select-menu'>
                <Select
                  value={locale.languageId}
                  onChange={onSwitchLanguage}
                  className='select-value'
                >
                  {languageData.map((language, index) => {
                    const { name, languageId } = language;
                    return (
                      // eslint-disable-next-line react/no-array-index-key
                      <MenuItem className="header-language" key={index} value={languageId}>{name}</MenuItem>
                    );
                  })}

                </Select>
                </div>
              </FormControl>
            </li>
          )}*/}
          <li className="list-inline-item user-nav help-list-icon cursor-pointer">
            <ButtonBase
              variant="contained"
              onClick={handleLocationClick}
              focusRipple
            >
              <div className="site-location-header-button">
                <LocationOnOutlinedIcon
                  className={isDashboard ? 'green-c-dark' : 'white-c'}
                  style={{ color: '#FFFFFF99', fontSize: 23 }}
                />
                <h1>{siteName} {description}</h1>
                <ArrowDropDownIcon style={{ color: '#80BBAD' }} />
              </div>
            </ButtonBase>
            <Popover
              id={'siteLocation'}
              open={true}
              style={{display: `${Boolean(locationAnchorEl) ? 'block' : 'none' }`}}
              anchorEl={locationAnchorEl}
              onClose={handleLocationClose}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
            >
              <LocationSelection closeSiteSelection={handleLocationClose} />
            </Popover>
          </li>
          <li className="list-inline-item user-nav help-list-icon cursor-pointer">
            <ButtonBase variant="contained" onClick={handleClick} focusRipple>
              <HelpIcon
                className={isDashboard ? 'green-c-dark' : 'white-c'}
                style={{ color: '#FFFFFF99', fontSize: 23 }}
              />
            </ButtonBase>
            <Popover
              id={'helpInfo'}
              open={Boolean(anchorEl)}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
            >
              <HelpInfoPopup onClose={handleClose} />
            </Popover>
          </li>
          {authUser && authUser !== null && (
            <li className="list-inline-item user-nav" style={{ width: 34 }}>
              <Dropdown
                className="quick-menu user-notification"
                isOpen={userInfo}
                toggle={onUserInfoSelect}
              >
                <DropdownToggle
                  className="d-inline-block"
                  tag="span"
                  data-toggle="dropdown"
                >
                  <IconButton
                    className="icon-btn size-32 h-100"
                    disableRipple={true}
                  >
                    <Badge
                      badgeContent="1"
                      color="error"
                      overlap="circular"
                      invisible={true}
                    >
                      <Avatar
                        style={{
                          color: '#003F2D',
                          fontSize: 12,
                          background: '#FFFFFF99',
                          paddingTop: '3px',
                        }}
                        sx={{ width: 20, height: 20 }}
                      >
                        {`${userDisplayName?.split(' ')[0][0]}${userDisplayName?.split(' ')[1][0]}`}
                      </Avatar>
                    </Badge>
                  </IconButton>
                </DropdownToggle>
                <DropdownMenu className="header-user-nav-dropdown" right={true}>
                  <UserInfoPopup
                    logOutUrl={logOutUrl}
                    isFusion={isFusion}
                    userDisplayName={userDisplayName}
                    userEmailId={userEmailId}
                  />
                </DropdownMenu>
              </Dropdown>
            </li>
          )}
          <li className="list-inline-item user-nav help-list-icon cursor-pointer">
            <ButtonBase
              variant="contained"
              onClick={handleAppsClick}
              focusRipple
            >
              <AppsIcon
                className={isDashboard ? 'green-c-dark' : 'white-c'}
                style={{ color: '#FFFFFF99' }}
                fontSize="medium"
              />
            </ButtonBase>
            <Popover
              id={'helpInfo'}
              open={Boolean(appsAnchorEl)}
              anchorEl={appsAnchorEl}
              onClose={handleAppsClose}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
            >
              <ExternalReports />
            </Popover>
          </li>
        </ul>
      </Toolbar>
    </AppBar>
  );
};
Index.propTypes = {
  hideMenu: PropTypes.bool,
  isFusion: PropTypes.bool,
  logOutUrl: PropTypes.string,
  match: PropTypes.shape({
    url: PropTypes.string.isRequired,
  }).isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
};
const mapStateToProps = (state) => ({
  logOutUrl: getLogOutUrl(state),
  fusionProjectsListUrl: '',
});
export default connect(mapStateToProps)(withRouter(Index));
