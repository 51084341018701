/* eslint-disable */
import {
  INCREMENT_PLANSLIST_GRID,
  INCREMENT_CAPITALPLAN_LIST,
  INCREMENT_PROJECTSLIST_GRID,
  INCREMENT_PROJECTTRACKERLIST_GRID,
  INCREMENT_ESTIMATORLIST_GRID,
  INCREMENT_MIGRATION_LIST,
  CLEAR_COMMON_ALERT,
  SET_COMMON_ALERT,
} from 'modules/cbre-flow-Common/constants/ActionTypes';

import { STOP_GLOBAL_LOADING, START_GLOBAL_LOADING } from '../constants/Common';

export const startGlobalLoading = () => ({
  type: START_GLOBAL_LOADING,
});
export const stopGlobalLoading = () => ({
  type: STOP_GLOBAL_LOADING,
});
export const incrementCapitalPlansListCount = () => {
  console.log('plans list count trigger ');
  return {
    type: INCREMENT_CAPITALPLAN_LIST,
  };
};
export const incrementMigrationListCount = () => {
  console.log('migration list count trigger ');
  return {
    type: INCREMENT_MIGRATION_LIST,
  };
};
export const incrementPlansGridCount = () => {
  console.log('plans grid count trigger ');
  return {
    type: INCREMENT_PLANSLIST_GRID,
  };
};
export const incrementProjectsGridCount = () => {
  console.log('projects grid count trigger ');
  return {
    type: INCREMENT_PROJECTSLIST_GRID,
  };
};
export const incrementProjectTrackerGridCount = () => {
  console.log('projects grid count trigger ');
  return {
    type: INCREMENT_PROJECTTRACKERLIST_GRID,
  };
};
export const incrementEstimatorGridCount = () => {
  console.log('estimator projects grid count trigger ');
  return {
    type: INCREMENT_ESTIMATORLIST_GRID,
  };
};

export const clearCommonAlerts = () => ({
  type: CLEAR_COMMON_ALERT,
});

type commonAlertProps = {
  message?: string;
  type: 'success' | 'failure' | 'warning' | 'information' | 'close';
};
export const setCommonAlerts = (payload: commonAlertProps) => ({
  type: SET_COMMON_ALERT,
  payload,
});
