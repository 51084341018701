
/* eslint-disable */
import React from 'react';
import IntlMessages from 'util/IntlMessages';

const sanitizer = require('sanitizer');

const UserInfoPopup = (props) => (
  < div >
    <div className="user-profile">
      {/* <img className="user-avatar border-0 size-40 rounded-circle"
             src={"https://via.placeholder.com/150x150"}
             alt="User"/> */}
      <div className="user-detail ml-2">
        <h4 className="user-name mb-0">{props.userDisplayName}</h4>
        <small title={props.userEmailId}>{props.userEmailId}</small>
      </div>
    </div>
    {/* <span className="pjm-link dropdown-item text-muted">
          <i className="zmdi zmdi-face zmdi-hc-fw mr-1 zmdi-hc-lg"/>
          <IntlMessages id="popup.profile"/>
        </span>
        <span className="pjm-link dropdown-item text-muted">
          <i className="zmdi zmdi-accounts zmdi-hc-fw mr-1 zmdi-hc-lg"/>
          <IntlMessages id="popup.clientaccess"/>
        </span>
      <span className="pjm-link dropdown-item text-muted">
          <i className="zmdi zmdi-settings zmdi-hc-fw mr-1 zmdi-hc-lg"/>
          <IntlMessages id="popup.setting"/>
        </span> */}
    <span
      className="pjm-link dropdown-item text-muted"
      onClick={() => {
        const pjmToken = sessionStorage.getItem('flow-token');
        sessionStorage.clear();
        let logoutLocation = `${sanitizer.sanitize(props.logOutUrl)}?token=${sanitizer.sanitize(pjmToken)}`;
        if (props.isFusion) {
          logoutLocation += `&redirectUrl=${window.location.href}&isFusion=true`;
        }
        window.location.href = encodeURI(logoutLocation);
      }}
    >
      <i className="zmdi zmdi-sign-in zmdi-hc-fw mr-1 zmdi-hc-lg" />
      <IntlMessages id="popup.logout" />
    </span>
  </div >
);

export default UserInfoPopup;
