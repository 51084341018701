/* eslint-disable react/jsx-filename-extension */
import { createSelector } from 'reselect';

import IntlMessages from 'util/IntlMessages';
import React from 'react';

const getConfigInfo = (state) => state && state.config;
const getNewOptions = (state) => state && state.addOptions;

export const getOptionsApiUrl = createSelector(
  [getConfigInfo],
  (configInfo) => (configInfo && configInfo.client && configInfo.client.flowApiUrl
    ? `${configInfo.client.flowApiUrl
    }user`
    : ''));

export const getContactApiUrl = createSelector(
  [getConfigInfo],
  (configInfo) => (configInfo && configInfo.client && configInfo.client.flowApiUrl
    ? `${configInfo.client.flowApiUrl
    }contact`
    : ''));

export const getNewOptionsAlertSuccessMessage = createSelector(
  [getNewOptions],
  (user) => {
    if (user && user.obj && user.obj.email) {
      //   if(planInfo.mode === 'add') {
      const initialMsg = (
        <IntlMessages
          id="cbre-flow-add-new-options.form.adduseralertsuccessmessage"
          defaultMessage="Added Options, {name}"
          values={{
            firstName: user.obj.firstName,
            lastName: user.obj.lastName,
          }}
        />
      );
      return initialMsg;
      //    }
    }
    return '';
  });
