
/* eslint-disable */
import apiService from 'util/apiService';
import {
  OPEN_ADD_NEW_OPTIONS_DIALOG,
  CLOSE_ADD_NEW_OPTIONS_DIALOG,
  DISABLE_ADD_NEW_OPTIONS,
  RESET_ADD_NEW_OPTIONS,
  START_ADD_NEW_OPTIONS,
  FAILURE_ADD_NEW_OPTIONS,
  SUCCESS_ADD_NEW_OPTIONS,
  DUPLICATE_ADD_NEW_OPTIONS,
  CLOSE_TOAST_ADD_NEW_OPTIONS,
} from './constants';

export const openAddNewOptionsDialog = (payload) => ({
  type: OPEN_ADD_NEW_OPTIONS_DIALOG,
  payload,
});

export const closeAddNewOptionsDialog = (payload) => ({
  type: CLOSE_ADD_NEW_OPTIONS_DIALOG,
  payload,
});

export const resetAddNewOptions = (payload) => ({
  type: RESET_ADD_NEW_OPTIONS,
  payload,
});

export const disableAddNewOptions = (payload) => ({
  type: DISABLE_ADD_NEW_OPTIONS,
  payload,
});

export const addNewOptions = (payload) => {
  const { mode, obj, saveApiUrl } = payload;
  return (dispatch) => {
    payload.errorDetails ? dispatch(addNewOptionsStart(payload.errorDetails)) : dispatch(addNewOptionsStart());

    return apiService.post(saveApiUrl, obj)
      .then((res) => {
        const {
          code, messageCode, message, data, status,
        } = res;
        if (code === 400 && (messageCode === 'USER_EXISTS_ERROR' || messageCode === 'CONTACT_EXISTS_ERROR')) {
          dispatch(addNewOptionsExistsFailure({ messageCode, obj }));
        } else
        if (code === 200 || code === 201) {
          dispatch(addNewOptionsSuccess({
            obj,
            // message: data.message,
            // mode
          }));
        } else {
          dispatch(addNewOptionsFailure({ messageCode: message, obj })); // messageCode
        }
      })
      .catch((err) => {
        console.log(err);
        dispatch(addNewOptionsFailure({ messageCode: err.message, obj }));
      });
  };
};

export const addNewOptionsStart = (payload) => ({
  type: START_ADD_NEW_OPTIONS,
  payload,
});

export const addNewOptionsSuccess = (payload) => ({
  type: SUCCESS_ADD_NEW_OPTIONS,
  payload,
});

export const addNewOptionsExistsFailure = (payload) => ({
  type: DUPLICATE_ADD_NEW_OPTIONS,
  payload,
});

export const addNewOptionsFailure = (payload) => ({
  type: FAILURE_ADD_NEW_OPTIONS,
  payload,
});

export const closeAddNewOptionsToast = (payload) => ({
  type: CLOSE_TOAST_ADD_NEW_OPTIONS,
  payload,
});
