
/* eslint-disable */

import React, { useEffect, useState } from 'react';
import { TextField } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Controller } from 'react-hook-form';
import IntlMessages from '../../util/IntlMessages';
import NumberFormat from "react-number-format";
import "./ControlledCurrencyBox.scss";

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: '19px',
    fontSize: '11px',
  },
}));

export default function ControlledCurrencyTextBox({
  name,
  label,
  control,
  rules,
  setValue,
  errors,
  errorMessages,
  module,
  getValues,
  onBlur,
  inputProps,
  disabled,
  focusField,
  allowEmptyFormatting = false,
  fixedDecimalScale = true,
  decimalSeparator=".",
  decimalScale=2,
  defaultValue='',
  intlValues = {},
  ...props
}) {
  const [validationMessage, setValidationMessage] = useState('');
  const [refData, setRefData] = useState(null);
  const [floatCurrencyData, setFloatCurrencyData] = useState('');
  const classes = useStyles();
  /* useEffect(() => {
    if (mode === 'edit') {
      setValue(name, props.defaultValue, { shouldValidate: true });
    }
  }, [mode]); */

  useEffect(() => {
    if (errors) {
      const val = module + errorMessages[errors];
      setValidationMessage(<IntlMessages
        id={val} values={intlValues}
      />);
    } else {
      setValidationMessage('');
    }
  }, [errors]);

  useEffect(() => {
    if(focusField === name) {
      refData.focus();
    }
  }, [focusField]);

  useEffect(() => {
    setValue(`${name}_float`,floatCurrencyData )
  }, [floatCurrencyData]);

  return (
      <>
      <Controller
          name={`${name}_float`}
          control={control}
          render={({ field }) => {
            // sending integer instead of string.
            return <input {...field} style={{display:'none'}} value={floatCurrencyData}  />;
          }}
      />
    <Controller
      render={({field})=> (
          <NumberFormat
              {...field}
              label={label}
              id={name}
              fullWidth={true}
              margin="normal"
              name={name}
              inputProps={inputProps}
              onBlur={onBlur}
              error={!!validationMessage}
              FormHelperTextProps={{
                classes: {
                  root: classes.root,
                },
              }}
              helperText={validationMessage}
              disabled={!!disabled}
              inputRef={el => { setRefData(el) }}
              {...props}
              decimalScale={decimalScale}
              allowEmptyFormatting={allowEmptyFormatting}
              fixedDecimalScale={fixedDecimalScale}
              decimalSeparator={decimalSeparator}
              className={`${(props.className || '')} currencyBox`}
              customInput={TextField}
              onValueChange={(v)=>{
                setFloatCurrencyData(v.value);
              }}
              control={control}
          />
          )}
      name={name}
      control={control}
      rules={rules}
      onChange={(e) => e}
      defaultValue={defaultValue}
      className="currencyBox"
    />
  </>
  );
}

ControlledCurrencyTextBox.propTypes = {
  ...NumberFormat.propTypes
}
