
/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { TextareaAutosize } from '@mui/material';
import { Controller } from 'react-hook-form';
import IntlMessages from 'util/IntlMessages';
import classNames from 'classnames';

export default function ControlledTextArea({
  name,
  placeholder,
  control,
  rules,
  onBlur,
  onChange,
  inputProps,
  inputRef,
  errors,
  errorMessages,
  defaultValue,
  disabled,
  minRows,
  maxRows,
  maxLength,
  module,
  label,
  intlValues,
  focusField,
  ...props
}) {
  const [validationMessage, setValidationMessage] = useState('');
  const [refData, setRefData] = useState(null);
  useEffect(() => {
    if (errors) {
      const val = module + errorMessages[errors];
      setValidationMessage(<IntlMessages
          id={val} values = {intlValues}
      />);
    } else {
      setValidationMessage('');
    }
  }, [errors]);

  useEffect(() => {
    if(focusField === name) {
      refData.focus();
    }
  }, [focusField]);
  return (
    <Controller
      render={({field})=> (
        <div >
          {label && <label className={classNames({ 'MuiFormLabel-root': true, 'Mui-error': errors })} htmlFor={name}> {label} </label>}
          <TextareaAutosize
            {...field}
            placeholder={placeholder}
            rows={minRows}
            rowsMin={minRows}
            rowsMax={maxRows}
            maxLength={maxLength}
            defaultValue={defaultValue}
            multiline
            // value={value}
            id={name}
            name={name}
            disabled={!!disabled}
            inputRef={el => { setRefData(el) }}
            onChange={(...rest)=> {field.onChange(...rest); onChange?.(...rest)}}
            onBlur={onBlur}
            style={{ width: '100%', ...errors && { border: 'red 1px solid' } }}
            {...props}
          />
          <span className="MuiFormHelperText-root Mui-error" > {validationMessage}</span>
        </div>
      )}
      name={name}
      control={control}
      rules={rules}
      onChange={(e) => e}
    />

  );
}
