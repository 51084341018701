import {
    GET_USER_INFO_START,
    GET_USER_INFO_SUCCESS,
    GET_USER_INFO_FAILURE,
} from '../constants';

const INIT_STATE = {
    userInfo: {},
    siteData: {}
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = INIT_STATE, action = {}) => {
    switch (action.type) {
        case GET_USER_INFO_START:
            return {
                ...state,
            };
        case GET_USER_INFO_SUCCESS:
            return {
                ...state,
                userInfo: action.payload,
                siteData: action.payload?.siteData,
            };
        case GET_USER_INFO_FAILURE:
            return {
                ...state,
            };

        default:
            return state;
    }
};
