import { createSelector } from "reselect";
const getConfigInfo = (state) => state && state.config;
const getApiDucksInfo = (state) => state && state['cbre-flow-api-ducks'];
export const getAppsListUrl = createSelector(
    [getConfigInfo],
    (configInfo) =>
        configInfo && configInfo.client && configInfo.client.flowApiUrl
            ? `${configInfo.client.flowApiUrl}api/apps`
            : '',
);

export const getAppsData = createSelector(
    [getApiDucksInfo],
    (apiDucksInfo) => {
        const { AppsList } = apiDucksInfo || {};
        return AppsList?.appsList || [];
    }
);