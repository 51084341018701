import {
  applyMiddleware, combineReducers, compose, createStore,
} from 'redux';
import { createBrowserHistory } from 'history';
import { routerMiddleware } from 'connected-react-router';
import createSagaMiddleware from 'redux-saga';
import thunk from 'redux-thunk';
import rootSaga from '../modules/cbre-flow-Common/sagas';
import reducers from '../modules/cbre-flow-Common/reducers';

const history = createBrowserHistory();
const routeMiddleware = routerMiddleware(history);
const sagaMiddleware = createSagaMiddleware();

const middlewares = [sagaMiddleware, routeMiddleware, thunk];
const composeEnhancers = (window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
        trace: true,
        traceLimit: 25,
    })) || compose;

export default function configureStore(initialState) {
  const newReducers = (state = { ...initialState }) => state;
  const updatedReducers = (reducerHistory) => combineReducers(
    {
      ...reducers(reducerHistory),
      config: newReducers,
    });
  const store = createStore(
    updatedReducers(history),
    initialState,
    composeEnhancers(applyMiddleware(...middlewares)
    ));
  sagaMiddleware.run(rootSaga);
  return store;
}
export { history };
