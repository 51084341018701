import apiService from 'util/apiService';
import {
    GET_USER_INFO_FAILURE,
    GET_USER_INFO_START,
    GET_USER_INFO_SUCCESS
} from "../constants";

export const getUserInfoStart = (payload) => ({
    type: GET_USER_INFO_START,
    payload,
});

export const getUserInfoSuccess = (payload) => ({
    type: GET_USER_INFO_SUCCESS,
    payload,
});

export const getUserInfoFailure = (payload) => ({
    type: GET_USER_INFO_FAILURE,
    payload,
});
export const getUserConfig = (payload, callback) => {
    const { apiUrl } = payload;
    return (dispatch) => {
        dispatch(getUserInfoStart())
        return apiService
            .get(apiUrl)
            .then((res) => {
                const { statusCode:code, messageCode, data } = res;
                if (code === 200) {
                    if(callback){
                        dispatch(callback(data?.siteData))
                    }
                    dispatch(getUserInfoSuccess(data));
                } else {
                    dispatch(getUserInfoFailure(messageCode));
                }
            })
            .catch((err) => {
                dispatch(getUserInfoFailure({ messageCode: err.message }));
            });
    };
};