import axios from 'axios';

class ApiService {
  constructor() {
    const service = axios.create({
      headers: {
        'Content-Type': 'application/json',
        Pragma: 'no-cache',
        'Cache-Control': 'no-cache, no-store',
      },

    });
    service.interceptors.request.use((config) => {
      // Do something before request is sent
      // eslint-disable-next-line no-param-reassign
      config.headers['x-authorization'] = sessionStorage.getItem('flow-token');
      // eslint-disable-next-line no-param-reassign
      config.headers['x-locale'] = localStorage.getItem('locale') || 'en_US';
      // eslint-disable-next-line no-param-reassign
      config.headers.AzureToken = sessionStorage.getItem('accessToken');
      return config;
    }, (error) =>
      // Do something with request error
      // eslint-disable-next-line implicit-arrow-linebreak
      Promise.reject(error)
    );
    service.interceptors.response.use(this.handleSuccess, this.handleError);
    this.service = service;
  }

  handleSuccess = (response) => {
    try {
      const { data } = response;
      const { loginRequired } = data || {};
      if (loginRequired) {
        sessionStorage.removeItem('flow-token');
        sessionStorage.removeItem('ui-token');
        if (process.env.NODE_ENV === 'development') {
          window.location.assign(`http://localhost:5000/login?redirectUrl=${window.location.href}`);
        } else {
          window.location.assign(`/login?redirectUrl=${window.location.href}`);
        }
        return 403;
      }
      if (response.headers.azuretoken) {
        //console.log('response headers --', response.headers);
        sessionStorage.setItem('accessToken', response.headers.azuretoken);
      }
      //console.log('Azure Token ======>', sessionStorage.getItem('accessToken'));
      // response.headers.locale // set session
    } catch (ex) {
      return ex;
    }
    return response;
  }

    handleError = (error) => {
      switch (error.response.status) {
        case 401:
          sessionStorage.removeItem('flow-token');
          sessionStorage.removeItem('ui-token');
          if (process.env.NODE_ENV === 'development') {
            window.location.assign(`http://localhost:5000/login?redirectUrl=${window.location.href}`);
          } else {
            window.location.assign(`/login?redirectUrl=${window.location.href}`);
          }
          break;
        default:
          Promise.reject(error);
          break;
      }
      return Promise.reject(error);
    }

  redirectTo = (document, path) => {
    // eslint-disable-next-line no-param-reassign
    document.location = path;
  }

  get(path, options) {
    return this.service.get(path, options)
      .then((response) => response.data)
      .catch((error) => error);
  }

  patch(path, payload, callback) {
    return this.service.request({
      method: 'PATCH',
      url: path,
      responseType: 'json',
      data: payload,
    }).then((response) => callback(response.status, response.data));
  }

  delete(path, payload) {
    return this.service.request({
      method: 'DELETE',
      url: path,
      responseType: 'json',
      data: payload,
    }).then((response) => response.data)
      .catch((error) => error);
  }

  post(path, payload) {
    return this.service.request({
      method: 'POST',
      url: path,
      responseType: 'json',
      data: payload,
    }).then((response) => response.data)
      .catch((error) => error);
  }

  put(path, payload) {
    return this.service.request({
      method: 'PUT',
      url: path,
      responseType: 'json',
      data: payload,
    }).then((response) => response.data)
      .catch((error) => error);
  }
}
const service = new ApiService();
export default service;
