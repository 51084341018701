
/* eslint-disable */
import {
  TOGGLE_COLLAPSED_NAV, 
  INCREMENT_PLANSLIST_GRID, 
  INCREMENT_PROJECTSLIST_GRID, 
  INCREMENT_CAPITALPLAN_LIST, 
  INCREMENT_PROJECTTRACKERLIST_GRID,
  INCREMENT_ESTIMATORLIST_GRID,
  INCREMENT_MIGRATION_LIST,
  CLEAR_COMMON_ALERT,
  SET_COMMON_ALERT,
} from '../constants/ActionTypes';
import { START_GLOBAL_LOADING, STOP_GLOBAL_LOADING } from '../constants/Common';

const initialSettings = {
  navCollapsed: false,
  plansListCounter: 0,
  plansListGridCounter: 0,
  projectsListGridCounter: 0,
  projectTrackerListGridCounter: 0,
  estimatorListGridCounter: 0,
  globalLoading: false,
  alerts:{}
};

const settings = (state = initialSettings, action) => {
  switch (action.type) {
    case '@@router/LOCATION_CHANGE':
      return {
        ...state,
        navCollapsed: false,
      };
    case START_GLOBAL_LOADING:
      return {
        ...state,
        globalLoading: true,
      };
    case STOP_GLOBAL_LOADING:
      return {
        ...state,
        globalLoading: false,
      };
    case TOGGLE_COLLAPSED_NAV:
      return {
        ...state,
        navCollapsed: !state.navCollapsed,
      };
    case INCREMENT_CAPITALPLAN_LIST:
      return {
        ...state,
        plansListCounter: state.plansListCounter + 1,
      };
      case INCREMENT_MIGRATION_LIST:
      return {
        ...state,
        migrationsListCounter: state.migrationsListCounter + 1,
      };
    case INCREMENT_PLANSLIST_GRID:
      return {
        ...state,
        plansListGridCounter: state.plansListGridCounter + 1,
      };
    case INCREMENT_PROJECTSLIST_GRID:
      return {
        ...state,
        projectsListGridCounter: state.projectsListGridCounter + 1,
      };
    case INCREMENT_PROJECTTRACKERLIST_GRID:
      return {
        ...state,
        projectTrackerListGridCounter: state.projectTrackerListGridCounter + 1,
      };
     case INCREMENT_ESTIMATORLIST_GRID:
        return {
          ...state,
          estimatorListGridCounter: state.estimatorListGridCounter + 1,
        };
    case CLEAR_COMMON_ALERT:
      return {
        ...state,
        alerts: {},
      };
    case SET_COMMON_ALERT:
      return {
        ...state,
        alerts:action.payload
      }
    default:
      return state;
  }
};

export default settings;
