
/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { TextField } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import IntlMessages from '../../util/IntlMessages';

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: '19px',
    fontSize: '11px',
  },
}));



export default function TextBox(props) {
  const {
    label, name, inputProps, inputRef, onChange, onBlur, errors, errorMessages, module, patternValidationProps, placeholder, variant, disabled, defaultValue, defaultText, ...otherProps
  } = props;
  const [validationMessage, setValidationMessage] = useState('');
  const classes = useStyles();
  const formattedVal = defaultValue?.replace(/&#39AB/g, '\'');

  useEffect(() => {
    if (errors) {
      const val = module + errorMessages[errors];
      setValidationMessage(<IntlMessages
        id={val}  values={patternValidationProps} 
      />);
    } else {
      setValidationMessage('');
    }
  }, [errors]);
  return (
    <>
      <TextField
        label={label}
        id={name}
        fullWidth={true}
        margin="normal"
        name={name}
        inputProps={inputProps}
        inputRef={inputRef}
        onChange={onChange}
        onBlur={onBlur}
        error={!!validationMessage}
        defaultValue = {formattedVal}
        defaultText = {formattedVal}
        disabled = {disabled ? JSON.parse(disabled) : false}
        FormHelperTextProps={{
          classes: {
            root: classes.root,
          },
        }}
        InputProps={{
          inputProps: {
            style: { textAlign: "center" }
          }
        }}
        helperText={validationMessage}
        {...otherProps}
      />
    </>
  );
}
