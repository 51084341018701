export const GET_SITE_INFO_START = 'GET_SITE_INFO_START';
export const GET_SITE_INFO_SUCCESS = 'GET_SITE_INFO_SUCCESS';
export const SET_SITE_INFO_SELECTED = 'SET_SITE_INFO_SELECTED';
export const GET_SITE_INFO_FAILURE = 'GET_SITE_INFO_FAILURE';
export const GET_DEFAULT_SITE_INFO_START = 'GET_DEFAULT_SITE_INFO_START';
export const GET_DEFAULT_SITE_INFO_SUCCESS = 'GET_DEFAULT__SITE_INFO_SUCCESS';
export const GET_DEFAULT_SITE_INFO_FAILURE = 'GET_DEFAULT__SITE_INFO_FAILURE';
export const SET_SITE_INFO_START = 'SET_SITE_INFO_START';
export const SET_SITE_INFO_SUCCESS = 'SET_SITE_INFO_SUCCESS';
export const SET_SITE_INFO_FAILURE = 'SET_SITE_INFO_FAILURE';
export const CLEAR_SITE_LOCATION_ALERT = 'CLEAR_SITE_LOCATION_ALERT';