/* eslint-disable no-underscore-dangle */
/* eslint-disable react/prop-types */
import { useState, useEffect, useCallback } from 'react';
import makeStyles from '@mui/styles/makeStyles';
// import DatePicker from '@mui/lab/DatePicker';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { Controller } from 'react-hook-form';
import { TextField } from '@mui/material';
import moment from 'moment';
import CalendarTodayOutlinedIcon from '@mui/icons-material/CalendarTodayOutlined';
import IntlMessages from 'util/IntlMessages';

import './dateSelectorCustomStyles.scss';

const useStyles = makeStyles({
  root: {
    minHeight: '19px',
    fontSize: '11px',
  },
});

function DateSelectorCustomDisplay(props) {
  const classes = useStyles();
  const {
    variant,
    autoOk,
    disableToolbar,
    format,
    id,
    name,
    label,
    views,
    control,
    rules,
    errors,
    defaultValue,
    checkInternalErrors,
    errorMessages,
    module,
    disabled,
    minDate,
    placeholder,
    minDateMessage,
    focusField,
    maskChar,
    onBlurDateFormat,
    onFocusDateFormat,
    lastModified,
    intlValues,
    openTo = "year"
  } = props;
  const [validationMessage, setValidationMessage] = useState('');
  const [internalError, setInternalError] = useState(false);
  // eslint-disable-next-line react/destructuring-assignment
  const dateDiff = props.dateDiff || 0;
  // eslint-disable-next-line react/destructuring-assignment
  const dateDifference = props.dateDifference || 0;

  useEffect(() => {
    if (errors) {
      const val = module + errorMessages[errors];
      setValidationMessage(<IntlMessages id={val} values={intlValues} />);
    } else {
      setValidationMessage('');
      setInternalError(false);
    }
  }, [errors]);

  useEffect(() => {
    if (focusField === name) {
      // eslint-disable-next-line
      document.getElementById(focusField)?.focus();
    }
  }, [focusField, name]);

  const handleError = useCallback(
    (error) => {
      if (error) {
        let invalidMsg = error;
        if (error === 'minDate') {
          invalidMsg = <IntlMessages id="msg.invalidDate" />;
        }
        if (error === 'maxDate') {
          invalidMsg = <IntlMessages id="msg.maximalDate" />;
        }
        if (error === 'invalidDate') {
          invalidMsg = <IntlMessages id="msg.minimalDate" />;
        }
        setValidationMessage(invalidMsg);
        setInternalError(true);
      }
      if (!errors && !error) {
        setValidationMessage('');
        setInternalError(false);
      }
    },
    [dateDiff, dateDifference, errors],
  );
  return (
    <div className="date-selector">
      <Controller
        render={({ field }) => (
          <DatePicker
            {...field}
            variant={variant || 'inline'}
            disableToolbar={disableToolbar || true}
            autoOk={autoOk || true}
            inputFormat={format}
            InputAdornmentProps={{ position: 'start' }}
            margin="dense"
            id={id}
            label={label}
            maskChar={maskChar}
            fullWidth={true}
            placeholder={placeholder || 'MM/DD/YYYY'}
            KeyboardButtonProps={{
              'aria-label': 'change date',
            }}
            views={views}
            error={!!validationMessage}
            onError={handleError}
            slots={{
              openPickerIcon: CalendarTodayOutlinedIcon
            }}
            slotProps={{
              textField: {
                helperText: validationMessage,
                error: !!validationMessage,
                id
              },
            }}
            FormHelperTextProps={{
              classes: {
                root: classes.root,
              },
            }}
            onBlurDateFormat={onBlurDateFormat}
            onFocusDateFormat={onFocusDateFormat}
            activeDateFormat={format}
            // helperText={validationMessage}
            minDate={minDate || moment('1900-01-01')}
            maxDate={moment('2100-12-31')}
            disabled={!!disabled}
            openTo={openTo || "year"}
            minDateMessage={minDateMessage}
            DialogProps={{
              className: 'dp-custom-class',
            }}
            {...props.onChange ? ({onChange: props.onChange}): {}}
          />
        )}
        control={control}
        defaultValue={defaultValue || null}
        rules={rules}
        name={name}
      />
    </div>
  );
}

export default DateSelectorCustomDisplay;
