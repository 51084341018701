import { createSelector } from "reselect";
const getConfigInfo = (state) => state && state.config;
const getApiDucksInfo = (state) => state && state['cbre-flow-api-ducks'];
export const getClientUsersListUrl = createSelector(
    [getConfigInfo],
    (configInfo) =>
        configInfo && configInfo.client && configInfo.client.flowApiUrl
            ? `${configInfo.client.flowApiUrl}api/user-role`
            : '',
);

export const getUsersListUrl = createSelector(
    [getConfigInfo],
    (configInfo) =>
        configInfo && configInfo.client && configInfo.client.flowApiUrl
            ? `${configInfo.client.flowApiUrl}api/users`
            : '',
);

export const getUserRoleSaveUrl = createSelector(
    [getConfigInfo],
    (configInfo) =>
        configInfo && configInfo.client && configInfo.client.flowApiUrl
            ? `${configInfo.client.flowApiUrl}api/user-role`
            : '',
);

export const getUserRoleByIdUrl = createSelector(
    [getConfigInfo],
    (configInfo) =>
        configInfo && configInfo.client && configInfo.client.flowApiUrl
            ? `${configInfo.client.flowApiUrl}api/user-role`
            : '',
);

export const removeUserRoleByIdUrl = createSelector(
    [getConfigInfo],
    (configInfo) =>
        configInfo && configInfo.client && configInfo.client.flowApiUrl
            ? `${configInfo.client.flowApiUrl}api/user-role/delete-users`
            : '',
);

export const getClientUsersData = createSelector(
    [getApiDucksInfo],
    (apiDucksInfo) => {
        const { AppsList } = apiDucksInfo || {};
        return AppsList?.clientsList || [];
    }
);