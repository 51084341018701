import {
    GET_APPS_INFO_START,
    GET_APPS_INFO_SUCCESS,
    GET_APPS_INFO_FAILURE,
} from '../constants/apps';

const INIT_STATE = {
    appsList: [],
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = INIT_STATE, action = {}) => {
    switch (action.type) {
        case GET_APPS_INFO_START:
            return {
                ...state,
            };
        case GET_APPS_INFO_SUCCESS:
            return {
                ...state,
                appsList: action.payload,
            };
        case GET_APPS_INFO_FAILURE:
            return {
                ...state,
            };

        default:
            return state;
    }
};
