import apiService from 'util/apiService';
import {
  CLEAR_SITE_LOCATION_ALERT,
  GET_DEFAULT_SITE_INFO_FAILURE,
  GET_DEFAULT_SITE_INFO_START,
  GET_DEFAULT_SITE_INFO_SUCCESS,
  GET_SITE_INFO_FAILURE,
  GET_SITE_INFO_START,
  GET_SITE_INFO_SUCCESS,
  SET_SITE_INFO_FAILURE,
  SET_SITE_INFO_START,
  SET_SITE_INFO_SUCCESS,
  SET_SITE_INFO_SELECTED,
} from './constants';
import {getUserConfig} from "modules/cbre-flow-api-ducks";
import { setCommonAlerts } from 'modules/cbre-flow-Common/actions';



export const getSiteInfoStart = (payload) => ({
  type: GET_SITE_INFO_START,
  payload,
});

export const getSiteInfoSuccess = (payload) => ({
  type: GET_SITE_INFO_SUCCESS,
  payload,
});

export const getSiteInfoFailure = (payload) => ({
  type: GET_SITE_INFO_FAILURE,
  payload,
});

export const setSiteInfoStart = (payload) => ({
  type: SET_SITE_INFO_START,
  payload,
});

export const setSiteInfoSuccess = (payload) => ({
  type: SET_SITE_INFO_SUCCESS,
  payload,
});

export const setSiteInfoSelected = (payload) => ({
  type: SET_SITE_INFO_SELECTED,
  payload,
});

export const setSiteInfoFailure = (payload) => ({
  type: SET_SITE_INFO_FAILURE,
  payload,
});

export const getDefaultSiteListStart = (payload)=>({
  type: GET_DEFAULT_SITE_INFO_START,
  payload,
})

export const getDefaultSiteListSuccess = (payload) => ({
  type: GET_DEFAULT_SITE_INFO_SUCCESS,
  payload,
});

export const getDefaultSiteListFailure = (payload) => ({
  type: GET_DEFAULT_SITE_INFO_FAILURE,
  payload,
});

export const clearSiteLocationAlerts = (payload) => ({
  type: CLEAR_SITE_LOCATION_ALERT,
  payload,
});


export const getSiteInfo = (payload) => {
  const { apiUrl } = payload;

  return (dispatch) => {
    // eslint-disable-next-line no-unused-expressions
    payload.errorDetails ? dispatch(getSiteInfoStart(payload.errorDetails))
      : dispatch(getSiteInfoStart());
    return apiService
      .get(apiUrl)
      .then((res) => {
        const {
          getSiteInfo:code, messageCode, data,
        } = res;
        if (code === 200) {
          dispatch(getSiteInfoSuccess(data));
        } else {
          dispatch(getSiteInfoFailure(messageCode));
        }
      })
      .catch((err) => {
        dispatch(getSiteInfoFailure({ messageCode: err.message }));
      });
  };
};

export const setSiteInfo = (payload) => {
  const { apiUrl, data, userUrl } = payload;

  return (dispatch) => {
    // eslint-disable-next-line no-unused-expressions
    payload.errorDetails ? dispatch(setSiteInfoStart(payload.errorDetails))
      : dispatch(setSiteInfoStart());
    return apiService
      .put(apiUrl, data)
      .then((res) => {
        const {
          statusCode: code, messageCode, data,
        } = res;
        if (code === 200) {
          dispatch(setSiteInfoSuccess(data?.siteData));
          dispatch(setCommonAlerts({ message: 'Site Info Updated Successfully', type: 'success' }))
          if(userUrl){
            dispatch(getUserConfig({ apiUrl: `${userUrl}` }))
          }
        } else {
          dispatch(setSiteInfoFailure(messageCode));
          dispatch(setCommonAlerts({ message: 'Site Info Update Failed', type: 'failure' }))
        }
        return res;
      })
      .catch((err) => {
        dispatch(setSiteInfoFailure({ messageCode: err.message }));
        dispatch(setCommonAlerts({ message: 'Site Info Update Failed', type: 'failure' }))
      });
  };
}

export const getDefaultSite = (payload) => {
  const { apiUrl } = payload;
  return (dispatch) => {
    dispatch(getDefaultSiteListStart());
    return apiService
      .get(apiUrl)
      .then((res) => {
        const { statusCode:code, messageCode, data } = res;
        if (code === 200) {
          dispatch(getDefaultSiteListSuccess(data.recordsList));
        } else {
          dispatch(getDefaultSiteListFailure(messageCode));
        }
      })
      .catch((err) => {
        dispatch(getDefaultSiteListFailure({ messageCode: err.message }));
      });
  };
};