
/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { TextField } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Controller } from 'react-hook-form';
import IntlMessages from '../../util/IntlMessages';
import './ControlledTextBox.scss'
import variables from 'styles/base/_variables.scss';

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: '19px',
    fontSize: '11px',
  },
  input: {
    fontSize: variables.fontSizeBase,
    letterSpacing: 'inherit',
    border: 0,
    boxSizing: 'content-box',
    background: 'none',
    margin: 0,
    display: 'block',
    minWidth: 0,
    width: '100%',
  },
  formControl: {
    margin: 0,
  },
  /*inputBackground: {
    backgroundColor: 'rgba(0, 0, 0, 0.04) !important',

    "&:after": {
      borderBottom: '2px solid #003f2d'
    }
  },*/
  inputLabelFocused: {
    // color: '#003f2d !important'
  },
}));

export default function ControlledTextBox({
  name,
  label,
  control,
  rules,
  setValue,
  errors,
  errorMessages,
  module,
  getValues,
  onBlur,
  onChange,
  inputProps,
  disabled,
  mode,
  focusField,
  intlValues,
  defaultText,
  variant,
  ...props
}) {
  const [validationMessage, setValidationMessage] = useState('');
  const [refData, setRefData] = useState(null);
  const classes = useStyles();
   useEffect(() => {
    if (mode === 'edit') {
      setValue(name, props.defaultValue, { shouldValidate: true });
    }
  }, [mode]);
  useEffect(() => {
    if(defaultText) {
      setValue(name, defaultText)
    }
  },[defaultText])


  useEffect(() => {
    if (errors) {
      const val = module + errorMessages[errors];
      setValidationMessage(<IntlMessages
        id={val} values={intlValues}
      />);
    } else {
      setValidationMessage('');
    }
  }, [errors]);

  useEffect(() => {
    if(focusField === name) {
      refData.focus();
    }
  }, [focusField]);
  return (
      <div className={'emerald-input textbox'}>
        <Controller
          render={({field})=>(
            <TextField
              {...field}
              label={label}
              id={name}
              fullWidth={true}
              margin="dense"
              name={name}
              variant={variant || 'outlined'}
              className={classes.root}
              InputProps={{
                inputProps: {
                  root: classes.root,
                  className: classes.input,
                  formControl: classes.formControl,
                  inputRef: props.innerRef,
                  children: props.children,
                  maxLength: inputProps?.maxLength,
                  ...props.innerProps,
                },
                className: classes.inputBackground
              }}
              InputLabelProps={{
                classes: {
                  focused: classes.inputLabelFocused,
                },
              }}
              // onChange={onChange}
              onBlur={onBlur}
              error={!!validationMessage}
              FormHelperTextProps={{
                classes: {
                  root: classes.root,
                },
              }}
              helperText={validationMessage}
              disabled={!!disabled}
              inputRef={el => { setRefData(el) }}
              {...props}
            />
              )}
          name={name}
          control={control}
          rules={rules}
          onChange={(e) => e}
        />
      </div>
  );
}
