
/* eslint-disable */
import React, { Fragment, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import IntlMessages from 'util/IntlMessages';
import Alerts from 'components/Alerts/Index';
import AddNewOptionsDialog from './addNewOptionDialog';
import {
  openAddNewOptionsDialog, closeAddNewOptionsDialog, closeAddNewOptionsToast, resetAddNewOptions,
} from '../ducks/actions';
import { getNewOptionsAlertSuccessMessage } from '../ducks/selectors';
import { Backdrop, Button, CircularProgress, Dialog, DialogTitle, Portal } from '@mui/material';
import "../styles/addNewOptions.scss"

const AddNewOptions = ({ ref, disabled, showActionButton= true }) => {
  const dispatch = useDispatch();
  const {
    open, loading, alert, error, userSaveApi
  } = useSelector((state) => state.addOptions);
  const toasterMessage = useSelector(getNewOptionsAlertSuccessMessage);
  useEffect(() => {
    if (open) {
      dispatch(closeAddNewOptionsDialog());
    }
    if (error) {
      dispatch(resetAddNewOptions());
    }
  }, []);

  const handleClickOpen = (e) => {
    dispatch(openAddNewOptionsDialog());
  };

  const handleAlertClose = () => {
    dispatch(closeAddNewOptionsToast());
  };

  return (
    <Fragment>
      {open &&  <AddNewOptionsDialog  userSaveApi={userSaveApi} />}
      <Alerts alertState={alert} handleRequestClose={handleAlertClose} message={toasterMessage} />
      <Backdrop open={loading} className="main-backdrop-custom">
        <CircularProgress className="main-loader-custom" />
      </Backdrop>
    </Fragment>
  );
};
export default AddNewOptions;
