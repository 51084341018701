import { createSelector } from "reselect";
const getConfigInfo = (state) => state && state.config;
const getSiteInfo = (state) => state && state["cbre-flow-site-location"].siteInfo;

export const getSite = createSelector([getSiteInfo], (siteInfo) => siteInfo);

export const getSiteId = createSelector([getSiteInfo], (siteInfo) => siteInfo.siteId);

export const getSiteInfoUrl = createSelector(
  [getConfigInfo],
  (configInfo) =>
    configInfo && configInfo.client && configInfo.client.flowApiUrl
      ? `${configInfo.client.flowApiUrl}api/site-info`
      : '',
);

export const setSiteInfoUrl = createSelector(
  [getConfigInfo],
  (configInfo) =>
    configInfo && configInfo.client && configInfo.client.flowApiUrl
      ? `${configInfo.client.flowApiUrl}api/site-info/update-user-site/`
      : '',
);
