/* eslint-disable */
import React, { useEffect, useRef } from 'react';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import { Controller } from 'react-hook-form';
import MenuItem from '@mui/material/MenuItem';
import FormHelperText from '@mui/material/FormHelperText';
import IntlMessages from 'util/IntlMessages';
import { withStyles, makeStyles } from '@mui/styles';
import InfoIcon from '@mui/icons-material/Info';
import Tooltip from '@mui/material/Tooltip';
import './SelectBox.scss';
import { ButtonBase } from '@mui/material';

const LightTooltip = withStyles((theme) => ({
  arrow: {
    color: 'rgba(0, 0, 0, 0.07)',
  },
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[1],
    fontSize: 13,
  },
}))(Tooltip);

const useStyles = makeStyles((theme) => ({
  /* formControl: {
     margin: theme.spacing(1),
     minWidth: 120
   },
   selectEmpty: {
     marginTop: theme.spacing(2)
   }, */
  menuPaper: {
    maxHeight: 500,
  },
}));

const SelectBox = ({
  name,
  label,
  control,
  data,
  menuItemId,
  menuItemValue,
  menuItemDisplayName,
  displayNameHasId,
  displayNameIdField,
  menuItemDescription,
  rules,
  setValue,
  errors,
  errorMessages,
  module,
  getValues,
  disabled,
  disableRequireHighlight,
  disableEmptyMenuItem,
  addedMenuProps,
  mode,
  focusField,
  defaultVal,
  FormHText,
  menuItemCount,
  intlValues,
  ...props
}) => {
  const labelId = `${name}-label`;
  const classes = useStyles();
  const selectRef = useRef();

  /*
  useEffect(() => {
    if (mode === 'edit') {
      setValue(name, defaultVal, { shouldValidate: true });
    }
  }, [mode]); */

  useEffect(() => {
    if (focusField === name) {
      selectRef.current.focus();
    }
  }, [focusField]);

  const handleClose = (e) => {
    if (typeof props.onClose === 'function') {
      props.onClose(e);
    }

    /*   if(a.getAttribute("data-value") == null && singleValue == '') {
           setValue(name, '', { shouldValidate: true })
       } */
    /*  if(e.target.value === undefined || e.target.value === '') {
          setValue(name, '', { shouldValidate: true })
      } */
  };
  return (
    <div className="emerald select-box">
      <FormControl
        {...props}
        error={!!errors && !disableRequireHighlight}
        disabled={!!disabled}
        variant="outlined"
      >
        <InputLabel id={labelId}>{label}</InputLabel>
        <Controller
          render={({ field }) => (
              <Select
                {...field}
                labelId={labelId}
                error={props?.error || false}
                label={label}
                fullWidth={true}
                inputRef={selectRef}
                renderValue={(value)=>value}
                margin='dense'
                MenuProps={
                  addedMenuProps
                    ? {
                        classes: { paper: classes.menuPaper },
                        ...addedMenuProps,
                      }
                    : { classes: { paper: classes.menuPaper } }
                }
                onClose={handleClose}
                className={props.className}
              >
                {disableEmptyMenuItem ? null : (
                  <MenuItem value="">
                    <IntlMessages id="menu.select" />
                  </MenuItem>
                )}
                {data?.map((element) => (
                  <MenuItem
                    value={
                      menuItemValue
                        ? element[menuItemValue]
                        : element[menuItemId]
                    }
                    key={element[menuItemId]}
                    style={
                      element.hasReadPermission
                        ? {
                            fontSize: '16px',
                            color: '#003f2d',
                            fontWeight: '650',
                          }
                        : {}
                    }
                  >
                    {displayNameHasId
                      ? `${element[displayNameIdField]} / ${element[menuItemDisplayName]}`
                      : element[menuItemDisplayName]}
                    {menuItemDescription ? (
                      <LightTooltip
                        title={element[menuItemDescription]}
                        style={{
                          marginLeft: '5px',
                          fill: '#009f6f',
                          fontSize: '0.875rem',
                          marginTop: '-5px',
                        }}
                        arrow={true}
                      >
                        <InfoIcon />
                      </LightTooltip>
                    ) : (
                      ''
                    )}
                    {menuItemCount ? <span className='position-absolute' style={{right:14, color:"#1A1A1A99", fontSize:14}}>{`${element[menuItemCount]}`}</span> : ''}
                    
                  </MenuItem>
                ))}
              </Select>
          )}
          name={name}
          control={control}
          rules={rules}
          FormHText={FormHText || ''}
        />
        {errors && !disableRequireHighlight ? (
          <FormHelperText style={{color: '#f44336', fontSize: '11px', marginLeft:10}}>
            <IntlMessages id={`${module}${errorMessages[errors]}`} values={intlValues} />
          </FormHelperText>
        ) : (
          <FormHelperText>{FormHText}</FormHelperText>
        )}
      </FormControl>
    </div>
  );
};
export default SelectBox;
