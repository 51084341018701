const validationMessages = {
  firstName: {
    required: 'textbox.firstnamerequiredvalidation',
    pattern: 'textbox.firstnamepatternvalidation',
    validate: 'textbox.firstnamepatternvalidation',
  },
  lastName: {
    required: 'textbox.lastnamerequiredvalidation',
    pattern: 'textbox.lastnamepatternvalidation',
  },
  email: {
    required: 'textbox.emailrequiredvalidation',
    pattern: 'textbox.emailpatternvalidation',
  },

};

export default validationMessages;
