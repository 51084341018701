export const OPEN_ADD_NEW_OPTIONS_DIALOG = 'CBRE-PJM-PROJECT-TRACKER-NEW-PROJECT-ADD-USER/OPEN_ADD_NEW_OPTIONS_DIALOG';
export const CLOSE_ADD_NEW_OPTIONS_DIALOG = 'CBRE-PJM-PROJECT-TRACKER-NEW-PROJECT-ADD-USER/CLOSE_ADD_NEW_OPTIONS_DIALOG';
export const RESET_ADD_NEW_OPTIONS = 'CBRE-PJM-PROJECT-TRACKER-NEW-PROJECT-ADD-USER/RESET_ADD_NEW_OPTIONS';

export const DISABLE_ADD_NEW_OPTIONS = 'CBRE-PJM-PROJECT-TRACKER-NEW-PROJECT-ADD-USER/DISABLE_ADD_NEW_OPTIONS';
export const START_ADD_NEW_OPTIONS = 'CBRE-PJM-PROJECT-TRACKER-NEW-PROJECT-ADD-USER/START_ADD_NEW_OPTIONS';

export const SUCCESS_ADD_NEW_OPTIONS = 'CBRE-PJM-PROJECT-TRACKER-NEW-PROJECT-ADD-USER/SUCCESS_ADD_NEW_OPTIONS';
export const FAILURE_ADD_NEW_OPTIONS = 'CBRE-PJM-PROJECT-TRACKER-NEW-PROJECT-ADD-USER/FAILURE_ADD_NEW_OPTIONS';
export const DUPLICATE_ADD_NEW_OPTIONS = 'CBRE-PJM-PROJECT-TRACKER-NEW-PROJECT-ADD-USER/DUPLICATE_ADD_NEW_OPTIONS';
export const CLOSE_TOAST_ADD_NEW_OPTIONS = 'CBRE-PJM-PROJECT-TRACKER-NEW-PROJECT-ADD-USER/CLOSE_TOAST_ADD_NEW_OPTIONS';
