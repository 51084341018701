
/* eslint-disable */
import {
  CBRE_FLOW_NEW_WORK_REQUEST_SUCCESS_COUNT,
} from './constants';

const INIT_STATE = {
  successCount:0,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {

    case CBRE_FLOW_NEW_WORK_REQUEST_SUCCESS_COUNT: {
      return {
        ...state,
        successCount:state.successCount + 1,
      }
    }
    default:
      return state;
  }
};
