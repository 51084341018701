
/* eslint-disable */
import React from 'react';
import AppRoutes from 'app';
import AppLayouts from './AppLayouts';

const AppLayout = (props) => {
  const Layout = AppLayouts.Vertical;
  return (
    <Layout hideMenu={props.hideMenu} isFusion={props.isFusion}>
      <AppRoutes {...props}/>
    </Layout>
  );
};

export default AppLayout;
