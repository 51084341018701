import {
  GET_SITE_INFO_START,
  GET_SITE_INFO_SUCCESS,
  GET_SITE_INFO_FAILURE,
  SET_SITE_INFO_START,
  SET_SITE_INFO_SUCCESS,
  SET_SITE_INFO_FAILURE,
  SET_SITE_INFO_SELECTED,
  GET_DEFAULT_SITE_INFO_FAILURE,
  GET_DEFAULT_SITE_INFO_START,
  GET_DEFAULT_SITE_INFO_SUCCESS,
  CLEAR_SITE_LOCATION_ALERT
} from './constants';

const INIT_STATE = {
  siteInfo: {},
  defaultSiteLists: [],
  alert: {},
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = INIT_STATE, action = {}) => {
  switch (action.type) {
    case GET_SITE_INFO_START:
      return {
        ...state,
        siteInfo: {},
      };
    case GET_SITE_INFO_SUCCESS:
      return {
        ...state,
        siteInfo: action.payload,
      };
    case GET_SITE_INFO_FAILURE:
      return {
        ...state,
      };
    case GET_DEFAULT_SITE_INFO_START:
      return {
        ...state,
        defaultSiteLists: [],
      };
    case GET_DEFAULT_SITE_INFO_SUCCESS:
      return {
        ...state,
        defaultSiteLists: action.payload,
      };
    case GET_DEFAULT_SITE_INFO_FAILURE:
      return {
        ...state,
      };

    case SET_SITE_INFO_START:
      return {
        ...state,
      };
    case SET_SITE_INFO_SUCCESS:
      return {
        ...state,
        siteInfo: action.payload,
      };
    case SET_SITE_INFO_SELECTED:
      return {
        ...state,
        siteInfo: action.payload,
      };
    case SET_SITE_INFO_FAILURE:
      return {
        ...state,
      };
   

    default:
      return state;
  }
};
