// eslint-disable-next-line no-useless-escape
export const EMAIL_VALIDATION_REGEX = /^(([^<>()*?|\[\]\\\/.,;:\s@"]+(\.[^<>()*?|\[\]\\\/.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
// eslint-disable-next-line no-useless-escape
export const TEXT_FIELD_VALIDATION_REGEX = /^([^<>*?|\\"])+$/;
// eslint-disable-next-line no-useless-escape
export const TEXT_FIELD_VALIDATION_REGEX_NO_FORWARD_SLASH = /^([^<>*?|\\\/"])+$/;
// eslint-disable-next-line no-useless-escape
export const POSITIVE_VALUE_WITH_TWO_DECIMAL_POINT = /^\d{1,13}(\.\d{1,2})?$/;
// eslint-disable-next-line no-useless-escape
export const POSITIVE_VALUE = /^[1-9]\d*$/;

export const TEXT_FIELD_VALIDATION_REGEX_LIMITED_SPECIAL_CHARACTERS = /^([^<>\\"])+$/;
