/* eslint-disable */
import flatten from 'flat';
import enMessages from '../locales/en_US.json';
import PendingApprovalLocale from 'modules/cbre-flow-lab-pending-approval/locales/en_US.json';
import LabDashboardLocale from 'modules/cbre-flow-lab-dashboard/locales/en_US.json';
import  UpcomingMaintenanceLocale from 'modules/cbre-flow-lab-upcoming-maintenance/locales/en_US.json';
import  LabInstrumentCreateRequest from 'modules/cbre-flow-lab-instrument-create-request/locales/en_US.json';
import  AddOptions from 'modules/cbre-flow-add-new-options/locales/en_US.json';
import  LabAddInstrument from 'modules/cbre-flow-lab-add-instrument/locales/en_US.json';
import  LabNewWorkRequest from 'modules/cbre-flow-lab-new-work-request/locales/en_US.json';
import  LabAddInstrumentFilter from 'modules/cbre-flow-lab-instrument-filter/locales/en_US.json';
import  RequestFilter from 'modules/cbre-flow-request-filter/locales/en_US.json';
import  UpcomingMaintenance from 'modules/cbre-flow-lab-upcoming-maintenance/locales/en_US.json';

const enLangMessages = flatten({
  ...enMessages,
  'cbre-flow-pending-approval': PendingApprovalLocale,
  'cbre-flow-lab-dashboard' : LabDashboardLocale,
  'cbre-flow-upcoming-maintenance' :  UpcomingMaintenanceLocale,
  'cbre-flow-lab-instrument-create-request': LabInstrumentCreateRequest,
  'cbre-flow-add-new-options': AddOptions,
  'cbre-flow-lab-add-instrument': LabAddInstrument,
  'cbre-flow-lab-new-work-request': LabNewWorkRequest,
  'cbre-flow-lab-instrument-filter': LabAddInstrumentFilter,
  'cbre-flow-request-filter': RequestFilter,
  'cbre-flow-lab-upcoming-maintenance': UpcomingMaintenance,
});
const EnLang = {
  messages: enLangMessages,
  locale: 'en-US',
};
export default EnLang;
