import * as React from "react";
import InsertChartIcon from "@mui/icons-material/InsertChart";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { ReactComponent as StarSvg } from 'assets/images/logo/Stars.svg';
import { useSelector } from "react-redux";
import { getSite } from "../ducks/selectors";
import "../styles/site-location.scss";

type ExternalReportsProps = {
    data?: any
};

const ExternalReports: React.FC<ExternalReportsProps> = () => {
    const { sitequicklinks: siteQuickLinks = [] } = useSelector(getSite)
    return (
        <>
            <div className="external-report-container">
                {
                    siteQuickLinks.length === 0 ? (
                        <div className="help-content w-100">
                            <p className="text-center p-3 m-0"> No external link available at this time.</p></div>)
                        : siteQuickLinks.map((data: any) => {
                            return <a className="item" rel="noreferrer" href={data.quickLinkUrl} target="_blank" key={data.quickLinkName}>
                                <div>
                                    <StarSvg className="icon" />
                                    <div className="label">{data.quickLinkName}</div>
                                </div>

                                <OpenInNewIcon className="icon" />
                            </a>
                        })}
            </div>

        </>
    );
};

export default ExternalReports;