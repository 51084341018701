
/* eslint-disable */
import { defaultLang, languageData } from 'components/LanguageSwitcher/data';
import AppLocale from 'lngProvider';
import {
  CHANGE_DIRECTION,
  CHANGE_NAVIGATION_STYLE,
  FIXED_DRAWER,
  COLLAPSED_DRAWER,
  MINI_DRAWER,
  HORIZONTAL_MENU_POSITION,
  INSIDE_THE_HEADER,
  BELOW_THE_HEADER,
  SWITCH_LANGUAGE,
  TOGGLE_COLLAPSED_NAV,
  VERTICAL_NAVIGATION,
  HORIZONTAL_NAVIGATION,
  WINDOW_WIDTH,
  SAVE_LANGUAGE_SELECTION_START,
  SAVE_LANGUAGE_SELECTION_SUCCESS,
  SAVE_LANGUAGE_SELECTION_FAILURE,
} from 'modules/cbre-flow-Common/constants/ActionTypes';
import { GREEN, WHITE } from 'modules/cbre-flow-Common/constants/ThemeColors';

const rltLocale = ['ar'];
const initialSettings = {
  navCollapsed: false,
  drawerType: MINI_DRAWER,
  width: window.innerWidth,
  isDirectionRTL: false,
  themeColor: GREEN,
  navigationStyle: VERTICAL_NAVIGATION,
  horizontalNavPosition: BELOW_THE_HEADER,
  locale: localStorage.getItem('locale') ? languageData.find(d => d.locale === localStorage.getItem('locale'))  || defaultLang : defaultLang,
  saveLanguage: {
    message: '',
    messageCode: '',
    loading: false,
  },
}

const settings = (state = initialSettings, action) => {
  switch (action.type) {
    case '@@router/LOCATION_CHANGE':
      return {
        ...state,
        navCollapsed: false,
      };
    case WINDOW_WIDTH:
      return {
        ...state,
        width: action.width,
      };
    case SWITCH_LANGUAGE:
      localStorage.setItem('locale', action.payload.locale)
      return {
        ...state,
        locale: action.payload,
        isDirectionRTL: rltLocale.includes(action.payload.locale),

      };
    case CHANGE_DIRECTION:
      return {
        ...state,
        isDirectionRTL: !state.isDirectionRTL,

      };

    case CHANGE_NAVIGATION_STYLE:
      return {
        ...state,
        navigationStyle: action.payload,
      };

    case HORIZONTAL_MENU_POSITION:
      return {
        ...state,
        horizontalNavPosition: action.payload,
      };

      case SAVE_LANGUAGE_SELECTION_START:
        return {
          ...state,
          saveLanguage: {
          message: '',
          loading: true,
          messageCode: '',
          }
        };
        case SAVE_LANGUAGE_SELECTION_SUCCESS:
        return {
          ...state,
          saveLanguage: {
            message: 'success',
            loading: false,
            messageCode: '',
            }
        };
        case SAVE_LANGUAGE_SELECTION_FAILURE:
        return {
          ...state,
          saveLanguage: {
            message: 'failure',
            loading: false,
            messageCode: 'there is ...... error',
            }
        };
    default:
      return state;
  }
};

export default settings;
