
/* eslint-disable */
import React from 'react';
import { List, ListItem } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { NavLink } from 'react-router-dom';
import Divider from '@mui/material/Divider';
import IntlMessages from '../../util/IntlMessages';
import { useSelector } from 'react-redux';

const useStyles = makeStyles((theme) => ({
  dividerInset: {
    margin: '10px 10px 10px 10px',
    marginLeft: '5px',
  },
}));
const NavMenuItem = (props) => {
  const {
    name, icon, link, isDivider, customIcon, isExternalURL, disabled, isbottom,
  } = props;
  const classes = useStyles();
  const { pathname } = useSelector((state)=> state.router?.location || {})

  return (
    <List component="div" className={`nav-menu-item ${disabled ? 'disabled' : ''} ${isDivider ? 'mt-3' : ''}`} style={{ position: isbottom === true ? 'fixed' : 'relative', bottom: 0}}>
      {isDivider && (<Divider variant="middle" className="ht-2" />)}
      {isExternalURL
        ? !disabled ? (
<a className={`prepend-icon nav-menu-link ${isDivider? "mt-3":""}`} href={link} target='_blank' rel='noopener noreferrer'>
        {/* Display an icon if any */}
        {!!icon && (
          <i className={'zmdi zmdi-hc-fw  zmdi-' + icon}/>
        )}
          {!!customIcon && (
              <div className={`${customIcon} ${disabled?'disabled':''}`}></div>
          )}
        <span className="nav-text"><IntlMessages id={name}/></span>
      </a>
):
          <div className="prepend-icon nav-menu-link">
                {!!icon && (
                  <i className={`zmdi zmdi-hc-fw  zmdi-${  icon}`} />
                  )}
                {!!customIcon && (
                  <div className={`${customIcon} ${disabled ? 'disabled' : ''} ${isDivider ? 'mt-3':''}`} />
                  )}
                <span className="nav-text"><IntlMessages id={name} /></span>
              </div>

        : (!disabled ? (
         <NavLink className={(isActive)=>`prepend-icon nav-menu-link ${disabled ? 'disabled' : ''} ${isDivider ? 'mt-3':''} ${(isActive || pathname.startsWith(link)) ? 'active' : ''}`} to={link}>
  {/* Display an icon if any */}
  {!!icon && (
          <i className={`zmdi zmdi-hc-fw  zmdi-${  icon}`} />
        )}
  {!!customIcon && (
          <div className={`${customIcon} ${disabled ? 'disabled' : ''}`} />
        )}
  <span className="nav-text"><IntlMessages id={name} /></span>
</NavLink>
        )
          :          <div className={`prepend-icon nav-menu-link ${disabled ? 'disabled' : ''} ${isDivider ? 'mt-3' : ''}`}>
            {/* Display an icon if any */}
            {!!icon && (
                  <i className={`zmdi zmdi-hc-fw  zmdi-${ icon}`} />
                )}
            {!!customIcon && (
                  <div className={`${customIcon} ${disabled ? 'disabled' : ''}`} />
                )}
            <span className="nav-text"><IntlMessages id={name} /></span>
          </div>)}
    </List>
  );
};
export default NavMenuItem;
