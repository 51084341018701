import { createSelector } from "reselect";
const getConfigInfo = (state) => state && state.config;
const getApiDucksInfo = (state) => state && state['cbre-flow-api-ducks'];
export const getClientsListUrl = createSelector(
    [getConfigInfo],
    (configInfo) =>
        configInfo && configInfo.client && configInfo.client.flowApiUrl
            ? `${configInfo.client.flowApiUrl}api/clients`
            : '',
);

export const getClientsData = createSelector(
    [getApiDucksInfo],
    (apiDucksInfo) => {
        const { AppsList } = apiDucksInfo || {};
        return AppsList?.clientsList || [];
    }
);