/* eslint-disable */
import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Select, { components } from 'react-select';
import { withStyles } from '@mui/styles';
import Typography from '@mui/material/Typography';
import { Controller } from 'react-hook-form';
import TextField from '@mui/material/TextField';
import Paper from '@mui/material/Paper';
import MenuItem from '@mui/material/MenuItem';
import FormHelperText from '@mui/material/FormHelperText';
import IntlMessages from 'util/IntlMessages';
import AsyncSelect from 'react-select/async';
import InputAdornment from '@mui/material/InputAdornment';
import './AsyncAutocompleteBoxComponent.scss';
import variables from 'styles/base/_variables.scss';
import { green } from '@mui/material/colors';
import AddNewOptions from 'modules/cbre-flow-add-new-options';
import { openAddNewOptionsDialog } from 'modules/cbre-flow-add-new-options/ducks/actions';
import { Button } from '@mui/material';

const styles = (theme) => ({
  root: {
    flexGrow: 1,
  },
  input: {
    display: 'flex',
    alignItems: 'flex-start',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'clip',
    color: variables.colorTextDark,
  },
  valueContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    flex: 1,
    alignItems: 'flex-start',
    overflow: 'hidden',
  },
  indicatorsContainer: {
    display: 'none !important',
  },
  noOptionsMessage: {},
  singleValue: {
    fontSize: `${variables.fontSizeBase} !important`,
  },
  placeholder: {},
  paper: {
    position: 'absolute',
    zIndex: "2 !important",
    marginTop: 1,
    left: 10,
    right: 0,
    width: 'calc(100% - 20px) !important',
  },
});

const DropdownIndicator = (props) =>
  props.isDisabled || props.selectProps.showDropDown === false ? null : (
    <components.DropdownIndicator {...props}>
      <i className="zmdi zmdi-caret-down zmdi-hc-fw zmdi-hc-lg" />
    </components.DropdownIndicator>
  );

function NoOptionsMessage(props) {
  return (
    <Typography
      color="textSecondary"
      className={props.selectProps.classes.noOptionsMessage}
      {...props.innerProps}
    >
      {props.children}
    </Typography>
  );
}

const Input = (props) => (
  <div>
    <components.Input maxLength="100" {...props} style={{ marginTop: -4, display: "block" }} />
  </div>
);

function inputComponent({ inputRef, ...props }) {
  return <div ref={inputRef} {...props} />;
}
const searchComponent = (props) => {
  if (props.selectProps.showSearchIcon) {
    return (
      <InputAdornment
        position="start"
        className={`${props.selectProps.value ? 'hidden' : 'async-search-icon'}`}
      >
        <i className="zmdi zmdi-search zmdi-hc-lg" />
      </InputAdornment>
    );
  }
};

function Control(props) {
  const handleTextChange = () => {
    if (props.getValue().length > 0) {
      props.clearValue();
    }
  };
  return (
    <TextField
      onChange={handleTextChange}
      error={props.selectProps.error}
      margin="dense"
      // label="Client*"
      label={props.selectProps.label}
      variant={props.variant || 'outlined'}
      fullWidth={true}
      InputProps={{
        inputComponent,
        inputProps: {
          root: props.selectProps.classes.root,
          className: props.selectProps.classes.input,
          inputRef: props.innerRef,
          children: props.children,
          ...props.innerProps,
        },
        className: props.selectProps.classes.inputBackground,
        endAdornment: searchComponent(props),
        ...(props.selectProps.InputProps || {}),
      }}
      InputLabelProps={{
        shrink: !!(
          props.isFocused ||
          props.selectProps.value ||
          props.selectProps.isShrink
        ),
      }}
      {...props.selectProps.textFieldProps}
    />
  );
}

function Option(props) {
  // console.log(props)
  return (
    <MenuItem
      title={props?.data?.email || props?.data?.contact?.email}
      buttonRef={props.innerRef}
      selected={props.isFocused}
      component="div"
      style={{
        fontWeight: props.isSelected ? 500 : 400,
      }}
      {...props.innerProps}
    >
      {props.children}
    </MenuItem>
  );
}

function Placeholder(props) {
  const { getStyles } = props;
  return (
    <Typography
      color="textSecondary"
      className={props.selectProps.classes.placeholder}
      {...props.innerProps}
      style={getStyles('placeholder', props)}
    >
      {props.children}
    </Typography>
  );
}

function SingleValue(props) {
  return (
    <Typography
      className={props.selectProps.classes.singleValue}
      {...props.innerProps}
    >
      {props.children}
    </Typography>
  );
}

function ValueContainer(props) {
  return (
    <div className={`valueContainer ${props.selectProps.classes.valueContainer}`}>
      {props.children}
    </div>
  );
}

function Menu(props) {
  const dispatch = useDispatch();
  const { addNewOptions = {} } = props.selectProps;
  return (
    <components.Menu  {...props}  className={`custom-style ${props.selectProps.classes.paper} ${addNewOptions.enabled && props.placement === "bottom" ? 'mb-5': ''}`} >
      {/* <Paper
        square={true}
       
        {...props.innerProps}
      > */}
        {props.children}
        {addNewOptions?.enabled === true ? (
          <Button
            variant="outlined"
            color="primary"
            onClick={() =>
              dispatch(
                openAddNewOptionsDialog({
                  mode: addNewOptions.mode,
                  body: addNewOptions.body,
                  cb: addNewOptions.cb,
                }),
              )
            }
            className="pjm-btn pjm-btn-sm personnel-btn w-100"
            role="button"
          >
            <IntlMessages id="cbre-flow-add-new-options.title.addNew" />
          </Button>
        ) : (
          ''
        )}
        {props.selectProps.dropDownbutton}
      {/* </Paper> */}
    </components.Menu>
  );
}

function AsyncAutocompleteBoxComponent(props) {
  const [single, setSingle] = useState(null);
  const [multi, setMulti] = useState(null);
  const [refData, setRefData] = useState(null);
  const {
    classes,
    customStyles,
    showSearchIcon,
    theme,
    control,
    errors,
    errorMessages,
    isMultiError,
    name,
    id,
    rules,
    setValue,
    module,
    suggestions,
    defaultVal,
    updateDisplay,
    handleUpdateDisplay,
    updateDisplayValue,
    getOptionValue,
    getOptionLabel,
    mode,
    label,
    required,
    loadOptions,
    defaultOptions,
    disabled,
    menuIsOpen,
    onMenuClose,
    // autoFocus,
    focusField,
    FormHText,
    onHandleChange,
    dropDownbutton,
    isShrink,
    style = {},
    isClearable = true,
    intlValues,
    addNewOptionsParams,
    addNewOptions,
    isSearchable = true,
    closeMenuOnSelect = true,
  } = props;
  const [validationMessage, setValidationMessage] = useState('');
  const containerRef = useRef()
  const handleChange =
    (field) =>
    (value, ...rest) => {
      setValue(name, value, { shouldValidate: true });
      field.onChange && field.onChange(value, ...rest);
      props.onChange && props.onChange(value, ...rest);
    };

  useEffect(() => {
    if (errors && !isMultiError) {
      const val = module + errorMessages[errors];
      setValidationMessage(<IntlMessages id={val} values={intlValues} />);
    } else if (errors && isMultiError) {
      setValidationMessage(
        <IntlMessages id={errorMessages} values={intlValues} />,
      );
    } else {
      setValidationMessage('');
    }
  }, [errors]);

  useEffect(() => {
    setSingle(defaultVal);
    if (mode === 'edit') {
      console.log("setting defaultvalue --- ",defaultVal)
      setValue(name, defaultVal, { shouldValidate: true });
    }
  }, [mode]);

  useEffect(() => {
    setSingle(defaultVal);
    if (mode === 'edit') {
      setValue(name, defaultVal, { shouldValidate: true });
    }
  }, [JSON.stringify(defaultVal)]);

  useEffect(() => {
    if (focusField === name) {
      refData.focus();
    }
  }, [focusField]);

  useEffect(() => {
    if (updateDisplay) {
      setSingle(updateDisplayValue);
      handleUpdateDisplay();
    }
  }, [updateDisplay]);

  const selectStyles = {
    input: (base) => ({
      ...base,
      color: theme.palette.text.primary,
      '& input': {
        fontSize: variables.fontSizeBase,
      },
    }),
    valueContainer: (base) => ({
      ...base,
      width: '100%',
    }),

    indicatorsContainer: (base) => ({
      ...base,
      '& > div:first-child': {
        // paddingRight:0
      },
    }),

    placeholder: (base) => ({
      ...base,
      fontSize: variables.fontSizeBase,
      color: 'red',
      fontWeight: 400,
    }),
    dropdownIndicator: (base) => ({
      ...base,
      paddingRight: '0 !important',
      '&:hover': {
        color: 'rgba(0, 0, 0, 0.54)',
        cursor: 'pointer',
      },
      color: 'rgba(0, 0, 0, 0.54)',
    }),
    menuPortal: (base) => ({
      ...base,
      zIndex: props.menuPortalTarget ? 1300 : 1,
    }),

    multiValueRemove: (styles, { data }) => ({
      ...styles,
      ':hover': {
        backgroundColor: green[800],
        color: 'white',
        cursor: 'pointer',
      },
    }),
  };

  const additionalProps = {};
  if (props.menuPortalTarget) {
    additionalProps.menuPortalTarget = props.menuPortalTarget;
    additionalProps.menuPosition = 'fixed';
  }

  if (props.InputProps) additionalProps.InputProps = props.InputProps;
  if (props.showDropDown !== undefined)
    additionalProps.showDropDown = props.showDropDown;
  if (props.value) additionalProps.value = props.value;
  if (props.defaultVal) additionalProps.defaultValue = props.defaultVal;
  if (props.isMulti) additionalProps.isMulti = props.isMulti;
  if (props.formatOptionLabel)
    additionalProps.formatOptionLabel = props.formatOptionLabel;

  return (
    <Controller
      render={({ field }) => (
        <div
          style={{ minWidth: '150px', ...style }}
          ref={containerRef}
          className={`${classes.root} ${customStyles} ${additionalProps.menuPortalTarget ? '': "non-portal-target"} position-relative emerald-input autocomplete ${props.isMulti ? 'multi-select-autocomplete' : ''}`}
        >
          <AsyncSelect
            {...field}
            className={
              disabled && validationMessage
                ? 'invalid-select disabled-prop-async-auto-not-label'
                : disabled && !validationMessage
                  ? 'disabled-prop-async-auto'
                  : ''
            }
            menuIsOpen={menuIsOpen}
            closeMenuOnSelect={closeMenuOnSelect}
            closeMenuOnScroll={(e) => {
              return e.target.contains(containerRef.current);
            }}
            onMenuClose={onMenuClose}
            classes={classes}
            styles={selectStyles}
            customStyles={customStyles}
            showSearchIcon={showSearchIcon}
            isSearchable={isSearchable}
            loadOptions={loadOptions}
            defaultOptions={defaultOptions}
            getOptionValue={getOptionValue}
            getOptionLabel={getOptionLabel}
            // color
            components={{
              Control,
              Menu,
              NoOptionsMessage,
              Option,
              // Placeholder,
              SingleValue,
              ValueContainer,
              Input,
              DropdownIndicator,
              IndicatorSeparator: () => null,
            }}
            // value={single && single[menuItemId] === '' ? '' : single}
            onChange={handleChange(field)}
            addNewOptions={addNewOptions}
            dropDownbutton={dropDownbutton}
            menuPlacement={'auto'}
            isShrink={isShrink}
            placeholder=""
            isClearable={isClearable}
            error={!!validationMessage}
            label={label}
            required={required}
            isDisabled={disabled}
            ref={(el) => {
              setRefData(el);
            }}
            FormHText={FormHText || ''}
            {...additionalProps}
          />
          {validationMessage ? (
            <FormHelperText
              style={{ color: '#f44336', fontSize: '11px', paddingLeft: 10 }}
            >
              {validationMessage}
            </FormHelperText>
          ) : (
            <FormHelperText style={{ paddingLeft: 10 }}>
              {FormHText}
            </FormHelperText>
          )}
        </div>
      )}
      name={name}
      control={control}
      rules={rules}
      defaultValue={defaultVal}
    />
  );
}

export default withStyles(styles, { withTheme: true })(
  AsyncAutocompleteBoxComponent,
);
