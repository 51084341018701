
/* eslint-disable */
import {
  CBRE_FLOW_ADD_INSTRUMENT_GET_ALL_LOOKUPS_SUCCESS,
  CBRE_FLOW_ADD_INSTRUMENT_SUCCESS_COUNT,
} from './constants';

const INIT_STATE = {
  lookupData:{},
  contactList:[],
  contactApproversList:{},
  defaultManufacturerList: [],
  successCount:0
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case CBRE_FLOW_ADD_INSTRUMENT_GET_ALL_LOOKUPS_SUCCESS:
      return {
        ...state,
        ...action.payload,
      };
    case CBRE_FLOW_ADD_INSTRUMENT_SUCCESS_COUNT:
      return {
        ...state,
        successCount: state.successCount + 1,
      };
    default:
      return state;
  }
};
